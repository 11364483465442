import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import Modal from 'react-modal';
import './playground.scss';

import { modalCss } from '../../views/utils/modalCss';
import iconSearch from 'images/icon_search.png';

class Playground extends React.Component {
  renderPlayground() {
    const { close } = this.props;
    return (
      <Modal
        isOpen={true}
        contentLabel="Activity Media Modal"
        onRequestClose={() => window.history.back()}
        shouldCloseOnOverlayClick={true}
        style={modalCss}
      >
        <button onClick={() => window.history.back()} className="btn-x">
          x
        </button>
        <div className="manageSections admin">
          <div className="bread-crumbs">
            <a>Builder Manager </a> > <a>Ideation Track</a> >{' '}
            <a>Problem Builder</a> > <a>Need Topic</a> > Manage Sections
          </div>
          <h3>Need - Manage Sections</h3>
          <p>Add or remove sections from this topic</p>
          <form className="section__searchbox">
            <input type="text" className="section__input" />
            <button className="section__searchbox__btn">
              <img src={iconSearch} />
            </button>
          </form>
          <div className="manageSections__results">
            <p className="manageSections__results__title">Sections (#)</p>
            <ul className="manageSections__results__table">
              <li className="manageSections__results__item">
                <span>Tips - Need</span>
                <div>
                  <a className="manageSections__results__remove">remove</a>
                </div>
              </li>
              <li className="manageSections__results__item">
                <span>Tips - Openings</span>
                <div>
                  <a className="manageSections__results__add">add</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    return this.renderPlayground();
  }
}

const mapDispatchToProps = () => ({
  close() {
    window.history.back();
  },
});

function mapStateToProps(state) {
  const { popups, appData, user } = state;

  return {};
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxForm({ form: 'createEvent', enableReinitialize: true })(Playground))
);
