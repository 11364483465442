import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
// import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import './quill.snow.css';


// Toolbar Settings
const toolbarOptions = [
  [{ header: [1, 2, 3, false, 6] }, 'bold', 'italic', 'underline', 'strike', 'image', 'video', 'link', 'blockquote', { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: ['', 'center', 'right'] }, 'clean']
];

/*
const BlockEmbed = Quill.import('blots/block/embed');
class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute('alt', value.alt);
    node.setAttribute('src', value.src);
    return node
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';
ImageBlot.className = 'inline-img';
Quill.register(ImageBlot);
*/

/**
 * props:
 *  handleChange
 *  initalValues:
 *  onComponentMount
 *
 */
class QuillEditor extends React.Component {
  constructor(props) {
    super(props);
    // Settings
    // this.formats = ['align', 'bold', 'italic', 'underline', 'variable'];
    // this.quillRef = null;
    // this.reactQuillRef = null;
    // this.editor = null;
    this.handleChange = this.handleChange.bind(this);
    this.modules = {
      toolbar: props.toolbar ? toolbarOptions : false,
    };
  }

  attachQuillRefs() {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    if (this.quillRef != null) return;
    this.quillRef = this.reactQuillRef.getEditor();
    this.editor = this.quillRef.editor;
  }

  /**
   * Update our redux store?
   */
  handleChange(content, delta, source, editor) {
    const { handleChangeDelta, handleChangeText } = this.props;
    handleChangeDelta(editor.getContents());
    handleChangeText(editor.getText());
    /*
    console.log('content', content);
    console.log('delta', delta);
    console.log('source', source);
    console.log('editor', editor);
    console.log('getContent', editor.getContents());
    */
  }

  render() {
    const { initialValues, readOnly } = this.props;
    return (
      <div className="quill-css">
        <ReactQuill
          className="quill-css"
          theme="snow"
          modules={this.modules}
          ref={(el) => { this.reactQuillRef = el; }}
          value={initialValues}
          onChange={this.handleChange}
          readOnly={readOnly}
        />
      </div>
    );
  }
}

/*
const formats = [
  'header', 'font', 'background', 'color', 'code', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent', 'script', 'align', 'direction',
  'link', 'image', 'code-block', 'formula', 'video'
]*/


/**
 * Can only used if used with redux state
 */

export default QuillEditor;
