import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import '../../css/tables.scss';
import { formatDate } from '../../utils/functions';

const HubsList = ({ hubs }) => (
  <div>
    <div>
      <div>
        <form>
          <Field
            type="text"
            component="input"
            placeholder="Search"
            name="search"
          />
          <br />
        </form>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th />
            <th>Name </th>
            <th>Id</th>
            <th>Inserted At</th>
            <th>Is Active</th>
            <th>Is Public</th>
            <th>Has Customer</th>
          </tr>
        </thead>
        <tbody>
          {hubs.map((hub, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td><Link to={`/hubs/${hub.id}`}>{hub.name}</Link></td>
                <td>{hub.id}</td>
                <td>{formatDate(hub.inserted_at)}</td>
                <td>{hub.is_active ? 'yes' : 'no'}</td>
                <td>{hub.is_public ? 'yes' : 'no'}</td>
                <td>{hub.customer_id ? 'yes' : 'no'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
);

function mapStateToProps(state) {
  const { hubs, form } = state;
  const searchString =
    form.hubListForm && form.hubListForm.values
      ? form.hubListForm.values.search.toLowerCase()
      : null;
  const filterhubList = searchString
    ? hubs.hubs.filter(hub =>
      hub
        .get('name')
        .toLowerCase()
        .includes(searchString)
    )
    : hubs.hubs;
  return {
    hubs: filterhubList.sortBy(project => project.get('inserted_at')).toJS()
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'hubListForm',
    enableReinitialize: true
  })(HubsList)
);
