import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import PrivateRoute from './PrivateRoute';

import Accounts from './views/accounts/accounts';
import AdminHome from './views/adminHome/AdminHome';
import AnswerBlueprintForm from './views/builderManager/answerBlueprints/AnswerBlueprintForm';
import BuilderBlueprint from './views/builderManager/builderBlueprints/BuilderBlueprint';
import BuilderBlueprintForm from './views/builderManager/builderBlueprints/BuilderBlueprintForm';
import BuilderManager from './views/builderManager/BuilderManager';
import HubInvitations from './views/hubs/invitations/HubInvitations';
import Hubs from './views/hubs/Hubs';
import ManageSections from './views/builderManager/topicBlueprints/ManageSections';
import Playground from './views/playground/Playground';
import Projects from './views/projects/projects';
import Login from './views/Login';
import TopicBlueprint from './views/builderManager/topicBlueprints/TopicBlueprint';
import TopicBlueprintForm from './views/builderManager/topicBlueprints/TopicBlueprintForm';
import TrackBlueprint from './views/builderManager/trackBlueprints/TrackBlueprint';
import TrackBlueprintForm from './views/builderManager/trackBlueprints/TrackBlueprintForm';
import SectionBlueprintForm from './views/builderManager/sectionBlueprints/SectionBlueprintForm';
import SectionBlueprints from './views/builderManager/sectionBlueprints/SectionBlueprints';
import Customers from './views/customers/Customers';
import EditCustomer from './views/customers/EditCustomer';
import CustomerForm from './views/customers/customerForm';
import Hub from './views/hubs/Hub';
import StepForm from 'views/hubs/components/multistepForm/StepForm';
import SingleCustomer from './Pages/Customers/SingleCustomer';

const RouterComponent = ({ isModalOpen, lastLocation }) => (
  <main className="mainSection min-vh-100">
    {/* ROUTES */}
    <Switch
      location={
        isModalOpen ? lastLocation || { pathname: '/' } : window.location
      }
    >
      {/* NEED TO CONFIGURE BASE NAMES!!! */}
      <PrivateRoute exact path="/" component={AdminHome} />
      <Route path="/playground" component={Playground} />

      {/* Login */}
      <Route path="/login" component={Login} />

      {/* projects */}
      <PrivateRoute path="/projects" component={Projects} />

      {/* accounts */}
      <PrivateRoute path="/accounts" component={Accounts} />

      {/* customers */}
      <PrivateRoute path="/customers/add" component={CustomerForm} />
      {/* <PrivateRoute path="/customers/edit/:c_id" component={EditCustomer} /> */}
      <PrivateRoute path="/customers/:c_id" component={SingleCustomer} />
      <PrivateRoute path="/customers" component={Customers} />

      {/* builder manager */}
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id/builders/:cb_id/topics/:tb_id/answers/add"
        component={AnswerBlueprintForm}
      />
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id/builders/:cb_id/topics/:tb_id/answers/:ab_id/edit"
        component={AnswerBlueprintForm}
      />
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id/builders/:cb_id/topics/:tb_id/edit"
        component={TopicBlueprintForm}
      />
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id/builders/:cb_id/topics/add"
        component={TopicBlueprintForm}
      />
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id/builders/:cb_id/topics/:tb_id"
        component={TopicBlueprint}
      />
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id/builders/:cb_id/edit"
        component={BuilderBlueprintForm}
      />
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id/builders/add"
        component={BuilderBlueprintForm}
      />
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id/builders/:cb_id"
        component={BuilderBlueprint}
      />
      <PrivateRoute
        path="/builder-manager/tracks/:ctb_id"
        component={TrackBlueprint}
      />
      <PrivateRoute
        path="/builder-manager/sections/add"
        component={SectionBlueprintForm}
      />
      <PrivateRoute
        path="/builder-manager/sections/:sb_id/edit"
        component={SectionBlueprintForm}
      />
      <PrivateRoute
        path="/builder-manager/sections"
        component={SectionBlueprints}
      />
      <PrivateRoute path="/builder-manager" component={BuilderManager} />

      {/* 
      <Route path="/builder-manager/:cbb_id/:cb_id/:tb_id/answer/:ab_id" component={AnswerBlueprintForm} />
      <Route path="/builder-manager/:cbb_id/:cb_id/:tb_id/workshop/add" component={WorkshopForm} />
      <Route path="/builder-manager/:cbb_id/:cb_id/:tb_id/workshop/:wb_id" component={WorkshopForm} />
 */}
      {/* <Route path="/builder-manager/:cbb_id/:cb_id/edit" component={BuilderBlueprintForm} /> */}
      {/* 

      <Route path="/builder-manager/:cbb_id/:cb_id" component={BuilderBlueprint} /> */}
      {/* <Route path="/builder-manager/:cbb_id" component={ChallengeBucket} /> */}

      {/* hubs */}
      <PrivateRoute path="/hubs/invitations" component={HubInvitations} />
      <PrivateRoute path="/hubs/i/step" component={StepForm} />

      <PrivateRoute path="/hubs/:h_id" component={Hub} />
      <PrivateRoute path="/hubs" component={Hubs} />

      {/* settings */}
    </Switch>

    {/* MODALS */}
    {/* challenge builder */}
    <Route path="/builder-manager/tracks/add" component={TrackBlueprintForm} />
    <Route
      path="/builder-manager/tracks/:ctb_id/edit"
      component={TrackBlueprintForm}
    />
    <Route
      path="/builder-manager/tracks/:ctb_id/builders/:cb_id/topics/:tb_id/manage-sections"
      component={ManageSections}
    />
  </main>
);

export default withLastLocation(RouterComponent);
