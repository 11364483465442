import React from 'react';
// import { Link } from 'react-router-dom';
import ProjectList from './projectList';

const Projects = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h3 className="m-0 font-weight-bold text-primary">Projects</h3>
          </div>
          <div className="card-body">
            <ProjectList />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Projects;
