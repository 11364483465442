import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function sectionTypeLabel(sectionType) {
  switch (sectionType) {
    case 'workshop':
      return 'workshop';
    case 'brief':
      return 'brief';
    case 'inspiration':
      return 'inspir. byte';
    default:
      return 'section';
  }
}

const BuilderManager = ({ challengeTrackBlueprints, sectionBlueprints }) => (
  <div className="admin container-fluid">
    <div className="[challenge__topic]">
      <h2 className="headtwo">Builder Manager</h2>

      <div>
        <h3 className="headthree">Tracks</h3>
        <Link to="/builder-manager/tracks/add">
          <button className="btn-right">+ Builder Track</button>
        </Link>
        {challengeTrackBlueprints.length ? (
          <ul className="display__layout">
            {challengeTrackBlueprints.map(ctb => {
              return (
                <li key={`cbb-${ctb.id}`}>
                  <p>{ctb.name}</p>
                  <Link to={`/builder-manager/tracks/${ctb.id}`}>
                    <p className="display__subtitle">
                      {ctb.challengeCount === 1
                        ? '1 builder'
                        : `${ctb.challengeCount} builders`}{' '}
                      <span className="display__subtitle--click">></span>
                    </p>
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : (
          <div>No challenge tracks</div>
        )}
      </div>

      <div>
        <h3 className="headthree header-flex">
          <span>Sections</span> <a>></a>
        </h3>
        <Link to="/builder-manager/sections/add">
          <button className="btn-right">+ New Section</button>
        </Link>

        {sectionBlueprints.length > 0 ? (
          <ul className="display__layout">
            {/* only show first five section blueprints */}
            {sectionBlueprints.slice(0, 5).map(sb => (
              <li>
                <div className="section__list--left">
                  <span className="section__type">
                    {sectionTypeLabel(sb.type)}
                  </span>
                  <span>{sb.title}</span>
                </div>
                <Link to={`/builder-manager/sections/${sb.id}/edit`}>></Link>
              </li>
            ))}

            {sectionBlueprints.length > 5 && (
              <li>
                <div className="section__see-all">
                  <Link to="/builder-manager/sections">See All Sections</Link>
                </div>
              </li>
            )}
          </ul>
        ) : (
          <span>No sections</span>
        )}
      </div>
    </div>
  </div>
);

const mapStateToProps = state => {
  const {
    challengeTrackBlueprints,
    challengeBlueprints,
    sectionBlueprints
  } = state.challengeBuilder;

  return {
    challengeTrackBlueprints: challengeTrackBlueprints
      .map(ctb => {
        const ctbId = ctb.get('id');
        const count = challengeBlueprints
          .filter(cb => cb.get('challenge_track_blueprint_id') === ctbId)
          .count();
        return ctb.set('challengeCount', count);
      })
      .sortBy(ctb => ctb.get('sort_order'))
      .toJS(),
    sectionBlueprints: sectionBlueprints.toJS()
  };
};

export default connect(mapStateToProps)(BuilderManager);
