import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HubsList from './hubsList';

class Hubs extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h3 className="m-0 font-weight-bold text-primary">Hubs</h3>
              </div>
              <div className="card-body">
                <HubsList />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hubs;
