import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { request } from '../../utils/functions';
import Cookie from 'js-cookie';
import { initializeApp } from '../../store/actions';
import { connect } from 'react-redux';

import logo from 'images/logo.png';
import user from 'store/user/userReducers';

const Login = ({ initializeApp, history }) => {
  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={(values) => {
        request(
          `${(window.env || process.env).REACT_APP_API_ENDPOINT}/api/auth/login`,
          null,
          'POST',
          {
            user: {
              email_address: values.email,
              password: values.password,
            },
          }
        ).then((res) => {
          Cookie.set('admin_token', res.data.jwt);
          if (res.data.is_rb_staff){
            initializeApp();
            history.push('/');
          } else{
            Cookie.remove('admin');
            alert('INVALID LOGIN');
          }          
        });
      }}
    >
      {({ }) => (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-6 bg-gradient-primary">
                      <div className="row justify-content-center align-items-center h-100">
                        <img className="img-fluid " src={logo} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">Admin Panel</h1>
                        </div>
                        <Form className="user">
                          <div className="form-group">
                            <Field type="email" name="email" className="form-control form-control-user" placeHolder="Enter email address..." />
                          </div>
                          <div className="form-group">
                            <Field
                              type="password"
                              name="password"
                              className="form-control form-control-user"
                              placeHolder="Password"
                            />
                          </div>

                          <button type="submit" className="btn btn-primary btn-user btn-block">
                            Login
                          </button>
                        </Form>
                        <hr />
                        <div className="text-center">
                          <a className="small" href="https://rebelbase.co/forgot-password">Forgot Password?</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  initializeApp,
};

export default connect(null, mapDispatchToProps)(Login);
