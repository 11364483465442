import { reset } from 'redux-form';

import { validateEmail, request } from 'utils/functions';
import { removePopups, popup } from 'shared/popup/popupActions';
import { doRedirect } from 'store/metadata/metadataActions';

export const SET_HUB_INVITATIONS = 'hubs/SET_HUB_INVITATIONS';
export const SET_HUB_INVITATION = 'hubs/SET_HUB_INVITATION';
export const DELETE_HUB_INVITATION = 'hubs/DELETE_HUB_INVITATION';
export const SET_HUBS = 'hubs/SET_HUBS';
export const SET_HUB_LIMITS = 'hubs/SET_HUB_LIMITS';
export const SET_SUBSCRIPTIONS = 'hubs/SET_SUBSCRIPTIONS';
export const SET_SUBSCRIPTION = 'hubs/SET_SUBSCRIPTION';
export const UPDATE_HUB_INVITATION = 'hubs/UPDATE_HUB_INVITATION';
export const RESET_HUB_INVITATION = 'hubs/RESET_HUB_INVITATION';

export function fetchHubInvitations() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/affiliations/invitations';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_HUB_INVITATIONS, data: response.data })
    );
  };
}

const validationFailedPopup =
  (message = 'Validation Failed') =>
  (dispatch) => {
    dispatch(
      popup(message, 'error', ' ', 'tc', 0, true, null, 'validation_failed')
    );
  };

const validateCreateHubStepOne =
  ({ name, email_address, hub_name }) =>
  (dispatch) => {
    if (!name || !email_address || !hub_name) {
      dispatch(validationFailedPopup('Please fill in all the fields.'));
      return false;
    }

    if (!validateEmail(email_address)) {
      dispatch(validationFailedPopup('Invalid email address.'));
      return false;
    }
    return true;
  };

export const createHubInvitationStep =
  (step, values, onSuccessCb = () => {}) =>
  (dispatch, getState) => {
    const { user } = getState();
    dispatch(removePopups());
    const jwt = user.get('jwt');

    if (!jwt) dispatch(doRedirect('/login'));

    // validate
    if (!values) return;
    if (step === 0 && !validateCreateHubStepOne(values)) return;

    dispatch({
      type: UPDATE_HUB_INVITATION,
      data: values,
    });

    if (step === 2) dispatch(createHubInvitation(onSuccessCb));
  };

export const resetCreateHubInvitation = () => (dispatch) => {
  dispatch({
    type: RESET_HUB_INVITATION,
  });
};

export function createHubInvitation(onSuccessCb) {
  return async (dispatch, getState) => {
    const { user, hubs } = getState();
    const jwt = user.get('jwt');
    const values = hubs.createHubInvitation;
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/affiliations/invitations';
    const payload = {
      create_hub_invitation: {
        name: values.name,
        email_address: values.email_address,
        hub_name: values.hub_name,
        customer_id: values.customer_id,
        hub_limits: {
          max_number_of_active_groups: values.max_number_of_active_groups,
          max_number_of_events_per_year: values.max_number_of_events_per_year,
          max_number_of_projects_per_event:
            values.max_number_of_projects_per_event,
          max_number_of_hub_admins: values.max_number_of_hub_admins,
        },
        subscription: {
          type: values.type,
          currency: values.currency,
          price: values.price,
          subscription_period: values.subscription_period,
          start_date: values.start_date,
          end_date: values.end_date,
        },
      },
    };
    try {
      const response = await request(url, jwt, 'POST', payload);
      if (response.ok === false) {
        const error = await response.json();
        const { errors } = error;
        if (!errors) throw new Error('Error in request body');
        throw new Error(
          `${Object.keys(errors)[0]}: ${Object.values(errors)[0]}`
        );
      }
      dispatch({ type: SET_HUB_INVITATION, data: response.data });
      dispatch(popup('Invitation sent.', 'success', ' ', 'tc', 5));
    } catch (err) {
      console.error(err.message || err);
      dispatch(
        validationFailedPopup(err.message || 'Error sending Invitation')
      );
    } finally {
      dispatch(resetCreateHubInvitation());
      onSuccessCb();
    }
  };
}

export function deleteHubInvitation(id) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/affiliations/invitations/${id}`;
    return request(url, jwt, 'DELETE').then(() =>
      dispatch({ type: DELETE_HUB_INVITATION, id })
    );
  };
}

export function fetchHubs() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/affiliations/hubs';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_HUBS, data: response.data })
    );
  };
}

export function fetchHubLimits() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v3/admin/hubs/hub-limits';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_HUB_LIMITS, data: response.data })
    );
  };
}

export const updateHubLimit =
  (hubLimitId, updatedValues, onSuccessCb) => (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    if (!jwt) return;
    const url = `${window.env.REACT_APP_API_ENDPOINT}/api/v3/admin/hubs/hub-limits/${hubLimitId}`;
    const payload = {
      hub: updatedValues,
    };
    request(url, jwt, 'PUT', payload)
      .then(() => {
        onSuccessCb();
        dispatch(popup('Hub Limit Updated', 'success', ' ', 'tc', 5));
        /**
         * @todo
         * use the response object to update the list of hub limits rather than making GET request to fetch all hub limits
         */
        dispatch(fetchHubLimits());
      })
      .catch((err) => console.log(err));
  };

export function fetchSubscriptions() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v3/admin/sales/subscriptions';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_SUBSCRIPTIONS, data: response.data })
    );
  };
}

export function createSubscription(
  values,
  onSuccessCb = () => {},
  onErrorCb = () => {}
) {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');

    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v3/admin/sales/subscriptions';
    const payload = {
      subscription: {
        ...values,
      },
    };

    // make request
    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({ type: SET_SUBSCRIPTION, data: response.data });
        onSuccessCb();
        dispatch(popup('Subscription added', 'success', ' ', 'tc', 5));
      })
      .catch((err) => {
        console.log(err);
        onErrorCb();
        validationFailedPopup('Error adding subscription');
      });
  };
}
