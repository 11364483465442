// QUILL FIELD
import React from 'react';
import { connect } from 'react-redux';

import { setDelta, setQuillText } from './quillActions';
import QuillEditor from './quillEditor';

/**
 * Props:
 * handleChange
 */
class QuillField extends React.Component {
  render() {
    const {
      delta,
      name,
      handleChangeDelta,
      handleChangeText,
      toolbar,
      readOnly,
    } = this.props;
    // create a steph-curry-like function to delete
    // const delta = quillDeltas.get(blurb.get('id'));
    const curryHandleChange = name => (
      deltaVal => (
        handleChangeDelta(name, deltaVal)
      )
    );

    const handleChange = curryHandleChange(name);

    // create a curried function to handle changing text values
    const curryHandleChangeText = name => (
      textValue => (
        handleChangeText(name, textValue)
      )
    );
    const curriedHandleChangeText = curryHandleChangeText(name);

    return (
      <QuillEditor
        initialValues={delta ? delta.toJS() : null}
        handleChangeDelta={handleChange}
        handleChangeText={curriedHandleChangeText}
        toolbar={typeof(toolbar) === "boolean" ? toolbar : true}
        readOnly={readOnly}
      />
    );
  }
}

/**
 * map a function that will set the quilljs Delta object
 */
const mapDispatchToProps = dispatch => ({
  // onChange, set the delta blurb value
  handleChangeDelta(name, delta) {
    dispatch(setDelta(name, delta));
  },
  // onChange, set the text value
  handleChangeText(name, textValue) {
    dispatch(setQuillText(name, textValue));
  },
});

const mapStateToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuillField);
