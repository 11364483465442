import { formatDate } from 'utils/functions';
import React, { Component, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createSubscription } from 'store/hubs/hubActions';
import AutoForm from './components/AutoForm';
import Confirm from './components/multistepForm/Confirm';

const HubSubscription = ({ hub, addSubscription }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const onSubmit = (values) => {
    setSubmitting(true);
    const valuesWithHubId = {
      ...values,
      hub_id: hub.id,
    };
    addSubscription(
      valuesWithHubId,
      () => {
        setSubmitting(false);
      },
      () => {
        setSubmitting(false);
      }
    );
  };
  return (
    <Confirm
      isUpdateSubscription
      onSubmit={onSubmit}
      label="Add Subscription"
      isSubmitting={isSubmitting}
    />
  );
};

class Hub extends Component {
  renderSubscriptionForm() {
    const { hub, customer } = this.props;
    try {
      this.setState({
        customer: customer,
      });
    } catch (exception_var) {}
    return (
      <HubSubscription hub={hub} addSubscription={this.props.addSubscription} />
    );
  }

  render() {
    const { hub, subscriptions, hubLimit, customer } = this.props;

    if (!hub) return null;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h3 className="m-0 font-weight-bold text-primary">
                  {hub.name}
                </h3>
              </div>
              <div className="card-body">
                <p>Created on: {formatDate(hub.inserted_at)}</p>
                <p>
                  Is Active:{' '}
                  {hub.is_active ? <span>true</span> : <span>false</span>}
                </p>
                <p>
                  Customer:{' '}
                  {hub.customer_id ? (
                    <Link to={`/customers/${hub.customer_id}`}>
                      {customer.organization_name}
                    </Link>
                  ) : (
                    <span>none</span>
                  )}
                </p>
                <hr />

                {hubLimit && (
                  <>
                    <h4>Limits</h4>
                    <AutoForm {...hubLimit} />
                    <hr />
                  </>
                )}

                <h4>Subscriptions</h4>
                {subscriptions.length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th />
                        <th>Type</th>
                        <th>Currency</th>
                        <th>Price</th>
                        <th>Subscription Period (months)</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        {/* <th>Sales Manager</th>
                      <th>Account Manager</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions.map((sub, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{sub.type}</td>
                            <td>{sub.currency}</td>
                            <td>{sub.price}</td>
                            <td>{sub.subscription_period}</td>
                            <td>{formatDate(sub.start_date)}</td>
                            <td>{formatDate(sub.end_date)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <span>There are no subscriptions for this hub.</span>
                )}
                <hr />
                {this.renderSubscriptionForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addSubscription(values, onSuccessCb, onErrorCb) {
    dispatch(createSubscription(values, onSuccessCb, onErrorCb));
  },
});

function mapStateToProps(state, props) {
  const { hubs, customers } = state;
  const hub = hubs.hubs.find(
    (h) => h.get('id') === parseInt(props.match.params.h_id, 10)
  );

  const hubLimit = hub
    ? hubs.hub_limits.find((hl) => hl.get('hub_id') === hub.get('id'))
    : null;

  const customer = customers
    ? customers.customers.find((c) => c.get('id') === hub.get('customer_id'))
    : null;

  return {
    hub: hub ? hub.toJS() : null,
    hubLimit: hubLimit ? hubLimit.toJS() : null,
    subscriptions: hub
      ? hubs.subscriptions
          .filter((s) => s?.get('hub_id') === hub?.get('id'))
          .toJS()
      : [],
    customer: customer
      ? customer.toJS()
      : { organization_name: null, id: null },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'SubscriptionForm', enableReinitialize: true })(Hub));
