import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateTopicSection } from '../../../store/builderManager/builderManagerActions';

function sectionTypeLabel(sectionType) {
  switch (sectionType) {
    case 'workshop':
      return 'workshop';
    case 'brief':
      return 'brief';
    case 'inspiration':
      return 'inspir. byte';
    default:
      return 'section';
  }
}

const TopicBlueprint = ({
  trackBlueprint,
  topicBlueprint,
  match,
  answerBlueprints,
  currentTopicSections,
  updateSortOrder,
}) => (
  <div className="container-fluid admin">
    <div className="[challenge]">
      {/* <div className="bread-crumbs">
        <Link to="/builder-manager">Builder Manager </Link> > <Link to={`/builder-manager/tracks/${match.params.ctb_id}`}>Track</Link>  > <Link to={`/builder-manager/tracks/${match.params.ctb_id}/bulders/${match.params.cb_id}`}>Builder</Link> > <Link to={`/builder-manager/tracks/${ctb_id}/builders/${cb_id}/topics/${tb_id}`}>Topic</Link> > New Answer
      </div> */}

      <div className="title__wrap">
        <div>
          <h3 className="headthree">TOPIC</h3>
          <h2 className="headtwo">{topicBlueprint.title}</h2>
          <h4>{topicBlueprint.question}</h4>
        </div>
        <Link
          to={`/builder-manager/tracks/${match.params.ctb_id}/builders/${match.params.cb_id}/topics/${match.params.tb_id}/edit`}
        >
          <span className="edit-link">edit</span>
        </Link>
      </div>

      <h3 className="headthree">SECTIONS</h3>
      <Link
        to={`/builder-manager/tracks/${match.params.ctb_id}/builders/${match.params.cb_id}/topics/${match.params.tb_id}/manage-sections`}
      >
        <button className="btn-right">manage sections</button>
      </Link>

      <ul className="display__layout">
        <li className="topic__drag-drop">
          <div className="topic__drag-drop__left">
            <span className="topic__drag-drop__type topic__drag-drop__type--intro">
              introduction
            </span>
            <span className="topic__drag-drop__title">
              {topicBlueprint.title}
            </span>
          </div>
          <Link
            to={`/builder-manager/tracks/${match.params.ctb_id}/builders/${match.params.cb_id}/topics/${match.params.tb_id}/edit`}
          >
            edit topic
          </Link>
        </li>
        {currentTopicSections.map((ts) => (
          <li key={`ts-${ts.id}`} className="topic__drag-drop">
            <div className="topic__drag-drop__left">
              {/* <img className="topic__drag-drop__icon" src={require("/icon_drag-drop.png")} /> */}
              <input
                type="number"
                min="0"
                step="1"
                value={ts.sort_order}
                onChange={(input) => updateSortOrder(ts.id, input.target.value)}
                className="topic__drag-drop__numbering"
              />
              <span className="topic__drag-drop__type">
                {sectionTypeLabel(ts.section_blueprint.type)}
              </span>
              <span>{ts.section_blueprint.title}</span>
            </div>
            <Link
              to={`/builder-manager/sections/${ts.section_blueprint.id}/edit`}
            >
              <p className="display__subtitle--click">&#62;</p>
            </Link>
          </li>
        ))}
      </ul>

      <hr />

      <h3 className="headthree">ANSWERS</h3>
      {!topicBlueprint.is_published && (
        <Link
          to={`/builder-manager/tracks/${match.params.ctb_id}/builders/${match.params.cb_id}/topics/${match.params.tb_id}/answers/add`}
        >
          <button className="btn-right">+ new answer</button>
        </Link>
      )}
      {answerBlueprints.length ? (
        <ul className="display__layout">
          {answerBlueprints.map((ab) => (
            <li key={`ab-${ab.id}`}>
              <p>
                {ab.title ? `${ab.title} - ` : null}
                {ab.type}
              </p>
              <Link
                to={`/builder-manager/tracks/${match.params.ctb_id}/builders/${match.params.cb_id}/topics/${match.params.tb_id}/answers/${ab.id}/edit`}
              >
                <p className="display__subtitle--click">></p>
              </Link>
            </li>
          ))}
        </ul>
      ) : (
        <div>No answers</div>
      )}

      <hr />
    </div>
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  updateSortOrder(topicSectionId, sortOrder) {
    dispatch(updateTopicSection(topicSectionId, sortOrder));
  },
});

const mapStateToProps = (state, props) => {
  const topicBlueprintId = parseInt(props.match.params.tb_id, 10);

  const {
    challengeTrackBlueprints,
    topicBlueprints,
    answerBlueprints,
    sectionBlueprints,
    topicSections,
  } = state.challengeBuilder;
  // get track blueprint info
  const trackBlueprint = challengeTrackBlueprints.find(
    (ctb) => ctb.get('id').toString() === props.match.params.ctb_id
  );

  // get challenge blueprint info

  // get topic blueprint info
  const topicBlueprint = topicBlueprints.find(
    (tb) => tb.get('id') === topicBlueprintId
  );

  // get all sections
  const currentTopicSections = topicSections
    .filter((ts) => ts.get('topic_blueprint_id') === topicBlueprintId)
    .map((ts) => {
      const sectionBlueprint = sectionBlueprints.find(
        (sb) => sb.get('id') === ts.get('section_blueprint_id')
      );
      return ts.set('section_blueprint', sectionBlueprint);
    })
    .sortBy((ts) => ts.get('sort_order'));

  return {
    answerBlueprints: answerBlueprints
      .filter(
        (ab) =>
          ab.get('topic_blueprint_id').toString() === props.match.params.tb_id
      )
      .sortBy((ab) => ab.get('sort_order'))
      .toJS(),
    topicBlueprint: topicBlueprint
      ? topicBlueprint.toJS()
      : { title: null, is_published: true },
    trackBlueprint: trackBlueprint ? trackBlueprint.toJS() : { title: null },
    currentTopicSections: currentTopicSections.toJS(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicBlueprint);
