import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as popups } from 'react-notification-system-redux';
import { reducer as form } from 'redux-form';

import challengeBuilder from './builderManager/builderManagerReducers';
import user from './user/userReducers';
import metadata from './metadata/metadataReducers';
import quill from '../shared/quill/quillReducers';
import sideNav from './sideNav/sideNavReducers';
import hubs from './hubs/hubReducers';
import users from './accounts/accountReducers';
import projects from './projects/projectReducers';
import customers from './customers/customerReducers';

export const history = createBrowserHistory();

const routerHistoryMiddleware = routerMiddleware(history);
const loggerMiddleware = createLogger();

export function configureStore(preloadedState) {
  return createStore(
    combineReducers({
      form,
      popups,
      challengeBuilder,
      user,
      metadata,
      quill,
      sideNav,
      hubs,
      users,
      projects,
      customers,
    }),
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        // loggerMiddleware,
        routerHistoryMiddleware
      )
    )
  );
}
