import { request, getBase64, postImage } from 'utils/functions';
import {
  SET_REDIRECT,
  doRedirect,
  SET_PROCESSING,
  RESET_PROCESSING,
} from '../metadata/metadataActions';
import { FLUSH_DELTAS } from 'shared/quill/quillActions';
import { popup, removePopups } from 'shared/popup/popupActions';

// *****************************
// * CHALLENGE TRACK BLUEPRINT *
// *****************************

export const SET_CHALLENGE_TRACK_BLUEPRINTS = 'SET_CHALLENGE_TRACK_BLUEPRINTS';
export const SET_CHALLENGE_TRACK_BLUEPRINT = 'SET_CHALLENGE_TRACK_BLUEPRINT';
export const SET_CHALLENGE_BUCKET_BLUEPRINT = 'SET_CHALLENGE_BUCKET_BLUEPRINT';
export const SET_WORKSHOP_BLUEPRINT = 'SET_WORKSHOP_BLUEPRINT';
export const UPDATE_WORKSHOP_BLUEPRINT = 'UPDATE_WORKSHOP_BLUEPRINT';
export const SET_WORKSHOP_BLUEPRINTS = 'SET_WORKSHOP_BLUEPRINTS';
export const SET_CHALLENGE_BUCKET_BLUEPRINTS =
  'SET_CHALLENGE_BUCKET_BLUEPRINTS';
export const UPDATE_CHALLENGE_TRACK_BLUEPRINTS =
  'UPDATE_CHALLENGE_TRACK_BLUEPRINTS';
export const UPDATE_CHALLENGE_BUCKET_BLUEPRINTS =
  'UPDATE_CHALLENGE_BUCKET_BLUEPRINTS';

export function setChallengeTrackBlueprints(data) {
  return {
    type: SET_CHALLENGE_TRACK_BLUEPRINTS,
    data,
  };
}

// create a new challenge bucket blueprint
export function addChallengeTrackBlueprint() {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });

    const { form, user, challengeBuilder } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/builder/challenge-track-blueprints';

    dispatch(removePopups());

    // validation
    if (
      !form.ChallengeTrackBlueprintForm ||
      !form.ChallengeTrackBlueprintForm.values ||
      !form.ChallengeTrackBlueprintForm.values.name
    ) {
      dispatch(
        popup(
          'Please fill in all the fields.',
          'error',
          ' ',
          'tc',
          0,
          true,
          null,
          'validation_failed'
        )
      );
      dispatch({ type: RESET_PROCESSING });
      return;
    }
    if (
      challengeBuilder.challengeBucketBlueprints.find(
        (cbb) =>
          cbb.get('name') === form.ChallengeTrackBlueprintForm.values.name
      )
    ) {
      dispatch(
        popup(
          'Name already exists.',
          'error',
          ' ',
          'tc',
          0,
          true,
          null,
          'validation_failed'
        )
      );
      dispatch({ type: RESET_PROCESSING });
      return;
    }

    const payload = {
      challenge_track_blueprint: {
        sort_order: form.ChallengeTrackBlueprintForm.values.sort_order
          ? form.ChallengeTrackBlueprintForm.values.sort_order
          : null,
        name: form.ChallengeTrackBlueprintForm.values.name,
        description: form.ChallengeTrackBlueprintForm.values.description,
      },
    };

    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({ type: SET_CHALLENGE_TRACK_BLUEPRINT, data: response.data });
        dispatch(doRedirect(`/builder-manager/tracks/${response.data.id}`));
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

// update a challenge bucket blueprint
export function updateChallengeTrackBlueprint(ctbId) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });

    const { form, user, challengeBuilder } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/builder/challenge-track-blueprints/${ctbId}`;

    dispatch(removePopups());

    // validation
    if (
      !form.ChallengeTrackBlueprintForm ||
      !form.ChallengeTrackBlueprintForm.values ||
      !form.ChallengeTrackBlueprintForm.values.name
    ) {
      dispatch(
        popup(
          'Please fill in all the fields.',
          'error',
          ' ',
          'tc',
          0,
          true,
          null,
          'validation_failed'
        )
      );
      dispatch({ type: RESET_PROCESSING });
      return;
    }

    if (
      challengeBuilder.challengeBucketBlueprints
        .filterNot((ctb) => ctb.get('id') === parseInt(ctbId, 10))
        .find(
          (ctb) =>
            ctb.get('name') === form.ChallengeTrackBlueprintForm.values.name
        )
    ) {
      dispatch(
        popup(
          'Name already exists.',
          'error',
          ' ',
          'tc',
          0,
          true,
          null,
          'validation_failed'
        )
      );
      dispatch({ type: RESET_PROCESSING });
      return;
    }

    const payload = {
      challenge_track_blueprint: {
        sort_order: form.ChallengeTrackBlueprintForm.values.sort_order,
        name: form.ChallengeTrackBlueprintForm.values.name,
        description: form.ChallengeTrackBlueprintForm.values.description,
      },
    };
    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch({
          type: UPDATE_CHALLENGE_TRACK_BLUEPRINTS,
          data: response.data,
        });
        dispatch(doRedirect(`/builder-manager/tracks/${response.data.id}`));
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

// get list of challenge bucket blueprints
export function fetchChallengeTrackBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/builder/challenge-track-blueprints';
    return request(url, jwt).then((response) =>
      dispatch(setChallengeTrackBlueprints(response.data))
    );
  };
}

// ***********************
// * CHALLENGE BLUEPRINT *
// ***********************

export const SET_CHALLENGE_BLUEPRINTS = 'SET_CHALLENGE_BLUEPRINTS';
export const SET_CHALLENGE_BLUEPRINT = 'SET_CHALLENGE_BLUEPRINT';
export const UPDATE_CHALLENGE_BLUEPRINTS = 'UPDATE_CHALLENGE_BLUEPRINTS';

export function setChallengeBlueprints(data) {
  return {
    type: SET_CHALLENGE_BLUEPRINTS,
    data,
  };
}

export function fetchChallengeBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/challenge-blueprints';
    return request(url, jwt).then((response) =>
      dispatch(setChallengeBlueprints(response.data))
    );
  };
}

export function fetchChallengeBucketBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/challenge-bucket-blueprints';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_CHALLENGE_BUCKET_BLUEPRINTS, data: response.data })
    );
  };
}

// create a new challenge blueprint
export function addChallengeBlueprint() {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });

    const { form, user, quill, challengeBuilder } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/challenge-blueprints';

    dispatch(removePopups());

    // validation
    if (
      challengeBuilder.challengeBlueprints.find(
        (cb) => cb.get('title') === form.ChallengeBlueprintForm.values.title
      )
    ) {
      dispatch(
        popup(
          'Title already exists.',
          'error',
          ' ',
          'tc',
          0,
          true,
          null,
          'validation_failed'
        )
      );
      dispatch({ type: RESET_PROCESSING });
      return;
    }

    // challenge blueprint data
    const cbForm = form.ChallengeBlueprintForm.values;
    const payload = {
      challenge_blueprint: {
        challenge_bucket_blueprint_id: parseInt(
          cbForm.challenge_bucket_blueprint_id,
          10
        ),
        challenge_track_blueprint_id: parseInt(
          cbForm.challenge_track_blueprint_id,
          10
        ),
        approximate_time: cbForm.approximate_time,
        title: cbForm.title,
        intro_title: cbForm.intro_title,
        about_title: cbForm.about_title,
        about_delta: quill.delta.get('about_delta'),
        title_spanish: cbForm.title_spanish,
        intro_title_spanish: cbForm.intro_title_spanish,
        about_title_spanish: cbForm.about_title_spanish,
        about_delta_spanish: quill.delta.get('about_delta_spanish'),
        level: parseInt(cbForm.level, 10),
        track_sort_order: cbForm.track_sort_order,
        bucket_sort_order: cbForm.bucket_sort_order,
        learning_outcome: {
          1: cbForm.learning_outcome1,
          2: cbForm.learning_outcome2,
          3: cbForm.learning_outcome3,
          4: cbForm.learning_outcome4,
          5: cbForm.learning_outcome5,
        },
        is_active: cbForm.is_active,
      },
    };

    request(url, jwt, 'POST', payload)
      .then((response) => {
        // cover image file
        const ciuForm = form.CoverImageUploadForm.values;
        const coverImg = ciuForm ? ciuForm.file[0] : null;
        const formData = new FormData();
        formData.append('_utf8', true);
        formData.append('challenge_blueprint[cover_img]', coverImg);
        postImage(`${url}/${response.data.id}`, jwt, 'PUT', formData)
          .then((response) => {
            dispatch({ type: SET_CHALLENGE_BLUEPRINT, data: response.data });
            dispatch(
              doRedirect(
                `/builder-manager/tracks/${response.data.challenge_track_blueprint_id}/builders/${response.data.id}`
              )
            );
            dispatch({ type: FLUSH_DELTAS });
            dispatch({ type: RESET_PROCESSING });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
}

// update a challenge blueprint
export function updateChallengeBlueprint(cbId) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });

    const { form, user, quill } = getState();
    const cbForm = form.ChallengeBlueprintForm.values;
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/challenge-blueprints/${cbId}`;
    const payload = {
      challenge_blueprint: {
        challenge_bucket_blueprint_id: parseInt(
          cbForm.challenge_bucket_blueprint_id,
          10
        ),
        challenge_track_blueprint_id: parseInt(
          cbForm.challenge_track_blueprint_id,
          10
        ),
        approximate_time: cbForm.approximate_time,
        title: cbForm.title,
        intro_title: cbForm.intro_title,
        about_title: cbForm.about_title,
        about_delta: quill.delta.get('about_delta'),
        level: parseInt(cbForm.level, 10),
        track_sort_order: cbForm.track_sort_order,
        bucket_sort_order: cbForm.bucket_sort_order,
        title_spanish: cbForm.title_spanish,
        intro_title_spanish: cbForm.intro_title_spanish,
        about_title_spanish: cbForm.about_title_spanish,
        about_delta_spanish: quill.delta.get('about_delta_spanish'),
        learning_outcome: {
          1: cbForm.learning_outcome1,
          2: cbForm.learning_outcome2,
          3: cbForm.learning_outcome3,
          4: cbForm.learning_outcome4,
          5: cbForm.learning_outcome5,
        },
        is_active: cbForm.is_active,
      },
    };

    // multipart forms need this data structure

    // formData.append('challenge_blueprint', payload);

    // if new cover image, convert image to base64 and append to payload
    const ciuForm = form.CoverImageUploadForm
      ? form.CoverImageUploadForm.values
      : null;
    if (ciuForm) {
      const formData = new FormData();
      formData.append('_utf8', true);
      formData.append('challenge_blueprint[cover_img]', ciuForm.file[0]);
      postImage(url, jwt, 'PUT', formData)
        .then((response) => {
          dispatch(setChallengeBlueprints(response));
          dispatch({ type: FLUSH_DELTAS });
          dispatch({ type: RESET_PROCESSING });
        })
        .catch((err) => console.log(err));
    }

    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch({ type: UPDATE_CHALLENGE_BLUEPRINTS, data: response.data });
        dispatch(
          doRedirect(
            `/builder-manager/tracks/${response.data.challenge_track_blueprint_id}/builders/${response.data.id}`
          )
        );
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

const isChallengeComplete = (challengeBuilder, cbId) => {
  const resp = (isComplete, message) => ({ isComplete, message });

  // check if builder has at least one topic
  const topics = challengeBuilder.topicBlueprints.filter(
    (tb) => tb.get('challenge_blueprint_id') === cbId
  );
  if (topics.size < 1)
    return resp(false, 'Builder should have at least one topic');

  // check each topic has an answer
  const answerTopicIds = challengeBuilder.answerBlueprints
    .toJS()
    .map((answer) => answer.topic_blueprint_id);
  if (
    topics
      .toJS()
      .map((topic) => answerTopicIds.includes(topic.id))
      .includes(false)
  )
    return resp(false, 'Each Topic in Builder should have an Answer');

  return resp(true, '');
};

// publish a challenge blueprint
export function publishChallengeBlueprint(
  cbId,
  onSuccessCb = () => {},
  onErrorCb = () => {}
) {
  return (dispatch, getState) => {
    dispatch(removePopups());
    dispatch({ type: SET_PROCESSING });
    const { user, challengeBuilder } = getState();
    const jwt = user.get('jwt');

    const { isComplete: canPublishChallenge, message } = isChallengeComplete(
      challengeBuilder,
      cbId
    );

    if (!canPublishChallenge) {
      dispatch({ type: RESET_PROCESSING });
      onErrorCb();
      dispatch(popup(message, 'error', ' ', 'tc', 5, true));
      return;
    }

    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/challenge-blueprints/${cbId}`;
    const payload = {
      challenge_blueprint: {
        is_published: true,
      },
    };

    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch(fetchTopicBlueprints());
        dispatch({ type: UPDATE_CHALLENGE_BLUEPRINTS, data: response.data });
        dispatch({ type: RESET_PROCESSING });
        onSuccessCb();
      })
      .catch((err) => {
        dispatch({ type: RESET_PROCESSING });
        dispatch(
          popup('Error publishing Builder', 'error', ' ', 'tc', 5, true)
        );
        onErrorCb(err);
      });
  };
}

// *********************
// * SECTION BLUEPRINT *
// *********************

export const SET_SECTION_BLUEPRINTS = 'builderManager/SET_SECTION_BLUEPRINTS';
export const SET_SECTION_BLUEPRINT = 'builderManager/SET_SECTION_BLUEPRINT';
export const DELETE_SECTION_BLUEPRINT =
  'builderManager/DELETE_SECTION_BLUEPRINT';

export function fetchSectionBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/builder-manager/section-blueprints';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_SECTION_BLUEPRINTS, data: response.data })
    );
  };
}

// create a new section blueprint
export function addSectionBlueprint() {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { form, user, quill } = getState();
    const sbForm = form.SectionBlueprintForm.values;
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/builder-manager/section-blueprints';
    const payload = {
      section_blueprint: {
        title: sbForm.title,
        info: quill.delta.get('section_info_delta'),
        title_spanish: sbForm.title_spanish,
        info_spanish: quill.delta.get('section_info_delta_spanish'),
        type: sbForm.type,
        rebel_map: null,
        rebel_map_url_1: sbForm.rebel_map_url_1,
        rebel_map_url_2: sbForm.rebel_map_url_2,
      },
    };

    if (payload.section_blueprint.type === 'workshop' && sbForm.file) {
      request(url, jwt, 'POST', payload)
        .then((response) => {
          const formData = new FormData();
          formData.append('_utf8', true);
          formData.append('section_blueprint[rebel_map]', sbForm.file[0]);
          postImage(`${url}/${response.data.id}`, jwt, 'PUT', formData).then(
            (response) => {
              dispatch({ type: SET_SECTION_BLUEPRINT, data: response.data });
              dispatch(
                popup('Section successfully added.', 'success', ' ', 'tc', 5)
              );
              dispatch({ type: FLUSH_DELTAS });
              dispatch(doRedirect('/builder-manager/sections'));
              dispatch({ type: RESET_PROCESSING });
            }
          );
        })
        .catch((err) => console.log(err));
    } else {
      request(url, jwt, 'POST', payload)
        .then((response) => {
          dispatch({ type: SET_SECTION_BLUEPRINT, data: response.data });
          dispatch(
            popup('Section successfully added.', 'success', ' ', 'tc', 5)
          );
          dispatch({ type: FLUSH_DELTAS });
          dispatch(doRedirect('/builder-manager/sections'));
          dispatch({ type: RESET_PROCESSING });
        })
        .catch((err) => console.log(err));
    }
  };
}

// update a section blueprint
export function editSectionBlueprint(sbId) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { form, user, quill } = getState();
    const sbForm = form.SectionBlueprintForm.values;
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/builder-manager/section-blueprints/${sbId}`;
    const payload = {
      section_blueprint: {
        title: sbForm.title,
        info: quill.delta.get('section_info_delta'),
        title_spanish: sbForm.title_spanish,
        info_spanish: quill.delta.get('section_info_delta_spanish'),
        type: sbForm.type,
        rebel_map_url_1: sbForm.rebel_map_url_1,
        rebel_map_url_2: sbForm.rebel_map_url_2,
      },
    };

    if (payload.section_blueprint.type === 'workshop' && sbForm.file) {
      request(url, jwt, 'PUT', payload)
        .then(() => {
          const formData = new FormData();
          formData.append('_utf8', true);
          formData.append('section_blueprint[rebel_map]', sbForm.file[0]);
          postImage(url, jwt, 'PUT', formData).then((response) => {
            dispatch({ type: SET_SECTION_BLUEPRINT, data: response.data });
            dispatch(
              popup('Section successfully updated.', 'success', ' ', 'tc', 5)
            );
            dispatch({ type: FLUSH_DELTAS });
            dispatch(doRedirect('/builder-manager/sections'));
            dispatch({ type: RESET_PROCESSING });
          });
        })
        .catch((err) => console.log(err));
    } else {
      request(url, jwt, 'PUT', payload)
        .then((response) => {
          dispatch({ type: SET_SECTION_BLUEPRINT, data: response.data });
          dispatch(
            popup('Section successfully updated.', 'success', ' ', 'tc', 5)
          );
          dispatch({ type: FLUSH_DELTAS });
          dispatch(doRedirect('/builder-manager/sections'));
          dispatch({ type: RESET_PROCESSING });
        })
        .catch((err) => console.log(err));
    }
  };
}

// delete a section blueprint
export function deleteSectionBlueprint(sbId) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/builder-manager/section-blueprints/${sbId}`;

    request(url, jwt, 'DELETE')
      .then(() => {
        dispatch({ type: DELETE_SECTION_BLUEPRINT, id: sbId });
        dispatch(popup('Section deleted.', 'success', ' ', 'tc', 5));
        dispatch(doRedirect(`/builder-manager/sections`));
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

// *******************
// * TOPIC BLUEPRINT *
// *******************

export const SET_TOPIC_BLUEPRINTS = 'SET_TOPIC_BLUEPRINTS';
export const SET_TOPIC_BLUEPRINT = 'SET_TOPIC_BLUEPRINT';
export const UPDATE_TOPIC_BLUEPRINTS = 'UPDATE_TOPIC_BLUEPRINTS';

export function setTopicBlueprints(data) {
  return {
    type: SET_TOPIC_BLUEPRINTS,
    data,
  };
}

export function fetchTopicBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/topic-blueprints';
    return request(url, jwt).then((response) =>
      dispatch(setTopicBlueprints(response.data))
    );
  };
}

// create a new topic blueprint
export function addTopicBlueprint(cbId, redirectTo = null) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { form, user, quill } = getState();
    const tbForm = form.TopicBlueprintForm.values;
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/topic-blueprints';

    dispatch(removePopups());

    // validation
    if (!tbForm.question) {
      dispatch(
        popup(
          'Question types are needed to publish a new topic blueprint.',
          'error',
          ' ',
          'tc',
          0,
          true,
          null,
          'validation_failed'
        )
      );
      dispatch({ type: RESET_PROCESSING });
      return;
    }

    const payload = {
      topic_blueprint: {
        challenge_blueprint_id: parseInt(cbId, 10),
        title: tbForm.title,
        question: tbForm.question,
        tip: tbForm.tip,
        introduction_delta: quill.delta.get('introduction_delta'),
        title_spanish: tbForm.title_spanish,
        tip_spanish: tbForm.tip_spanish,
        question_spanish: tbForm.question_spanish,
        introduction_delta_spanish: quill.delta.get(
          'introduction_delta_spanish'
        ),
        sort_order: tbForm.sort_order,
      },
    };
    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({ type: SET_TOPIC_BLUEPRINT, data: response.data });
        dispatch(popup('Topic successfully added.', 'success', ' ', 'tc', 5));
        dispatch({ type: FLUSH_DELTAS });
        dispatch(doRedirect(redirectTo));
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

const isTopicComplete = (challengeBuilder, tbId) => {
  const resp = (isComplete, message) => ({ isComplete, message });

  if (
    !challengeBuilder?.answerBlueprints
      ?.toJS()
      .map((answer) => answer.topic_blueprint_id)
      .includes(tbId)
  )
    return resp(false, 'Cannot publish topic without an answer');

  return resp(true, '');
};

// publish a topic blueprint
export function publishTopicBlueprint(
  tbId,
  onSuccessCb = () => {},
  onErrorCb = () => {}
) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { user, challengeBuilder } = getState();
    const jwt = user.get('jwt');

    const { isComplete: canPublishTopic, message } = isTopicComplete(
      challengeBuilder,
      tbId
    );

    if (!canPublishTopic) {
      dispatch({ type: RESET_PROCESSING });
      onErrorCb();
      dispatch(popup(message, 'error', ' ', 'tc', 5, true));
      return;
    }
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/topic-blueprints/${tbId}`;
    const payload = {
      topic_blueprint: {
        is_published: true,
      },
    };

    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch({ type: UPDATE_TOPIC_BLUEPRINTS, data: response.data });
        dispatch({ type: RESET_PROCESSING });
        onSuccessCb();
      })
      .catch((err) => {
        dispatch({ type: RESET_PROCESSING });
        dispatch(popup('Error publishing Topic', 'error', ' ', 'tc', 5, true));
        onErrorCb(err);
      });
  };
}

// update a topic blueprint
export function updateTopicBlueprint(tbId) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { form, user, quill } = getState();
    const tbForm = form.TopicBlueprintForm.values;
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/topic-blueprints/${tbId}`;
    const payload = {
      topic_blueprint: {
        title: tbForm.title,
        question: tbForm.question,
        tip: tbForm.tip,
        introduction_delta: quill.delta.get('introduction_delta'),
        sort_order: tbForm.sort_order,
        title_spanish: tbForm.title_spanish,
        tip_spanish: tbForm.tip_spanish,
        question_spanish: tbForm.question_spanish,
        introduction_delta_spanish: quill.delta.get(
          'introduction_delta_spanish'
        ),
      },
    };
    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch({ type: UPDATE_TOPIC_BLUEPRINTS, data: response.data });
        dispatch(popup('Topic successfully updated.', 'success', ' ', 'tc', 5));
        dispatch({ type: FLUSH_DELTAS });
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

// ******************
// * TOPIC SECTIONS *
// ******************
export const SET_TOPIC_SECTIONS = 'builderManager/SET_TOPIC_SECTIONS';
export const SET_TOPIC_SECTION = 'builderManager/SET_TOPIC_SECTION';
export const DELETE_TOPIC_SECTION = 'builderManager/DELETE_TOPIC_SECTION';

function fetchTopicSections() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/builder-manager/topic-sections';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_TOPIC_SECTIONS, data: response.data })
    );
  };
}

export function addTopicSection(topicBlueprintId, sectionBlueprintId) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v2/admin/builder-manager/topic-sections';
    const payload = {
      topic_section: {
        topic_blueprint_id: topicBlueprintId,
        section_blueprint_id: sectionBlueprintId,
      },
    };
    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({ type: SET_TOPIC_SECTION, data: response.data });
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

export function updateTopicSection(topicSectionId, sortOrder) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/builder-manager/topic-sections/${topicSectionId}`;
    const payload = {
      topic_section: {
        sort_order: sortOrder,
      },
    };
    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch({ type: SET_TOPIC_SECTION, data: response.data });
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

export function removeTopicSection(topicSectionId) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/builder-manager/topic-sections/${topicSectionId}`;

    request(url, jwt, 'DELETE')
      .then(() => {
        dispatch({ type: DELETE_TOPIC_SECTION, id: topicSectionId });
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

// ********************
// * ANSWER BLUEPRINT *
// ********************

export const SET_ANSWER_BLUEPRINTS = 'SET_ANSWER_BLUEPRINTS';
export const SET_ANSWER_BLUEPRINT = 'SET_ANSWER_BLUEPRINT';
export const UPDATE_ANSWER_BLUEPRINT = 'UPDATE_ANSWER_BLUEPRINT';

export function setAnswerBlueprints(data) {
  return {
    type: SET_ANSWER_BLUEPRINTS,
    data,
  };
}

export function fetchAnswerBlueprints() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/answer-blueprints';
    return request(url, jwt).then((response) =>
      dispatch(setAnswerBlueprints(response.data))
    );
  };
}

// create a new answer blueprint
export function addAnswerBlueprint(tbId, redirectTo = null) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { form, user } = getState();
    const abForm = form.AnswerBlueprintForm.values;
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/answer-blueprints';
    const payload = {
      answer_blueprint: {
        topic_blueprint_id: parseInt(tbId, 10),
        title: abForm.title,
        sort_order: parseInt(abForm.sort_order, 10),
        type: abForm.type,
        options: { ...abForm.options },
        type_label: abForm.type_label,
      },
    };
    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({ type: SET_ANSWER_BLUEPRINT, data: response.data });
        dispatch(popup('Answer successfully added.', 'success', ' ', 'tc', 5));
        dispatch({ type: RESET_PROCESSING });
        dispatch(doRedirect(redirectTo));
      })
      .catch((err) => console.log(err));
  };
}

// update an answer blueprint
export function updateAnswerBlueprint(abId) {
  return (dispatch, getState) => {
    dispatch({ type: SET_PROCESSING });
    const { form, user } = getState();
    const abForm = form.AnswerBlueprintForm.values;
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      `/api/v2/admin/answer-blueprints/${abId}`;
    const payload = {
      answer_blueprint: {
        title: abForm.title,
        sort_order: parseInt(abForm.sort_order, 10),
        type: abForm.type,
        options: { ...abForm.options },
        type_label: abForm.type_label,
      },
    };
    request(url, jwt, 'PUT', payload)
      .then((response) => {
        dispatch({ type: UPDATE_ANSWER_BLUEPRINT, data: response.data });
        dispatch(
          popup('Answer successfully updated.', 'success', ' ', 'tc', 5)
        );
        dispatch({ type: RESET_PROCESSING });
      })
      .catch((err) => console.log(err));
  };
}

// ***********
// * GENERAL *
// ***********

export function fetchChallengeBuilderData() {
  return (dispatch) => {
    dispatch(fetchAnswerBlueprints());
    dispatch(fetchChallengeBlueprints());
    dispatch(fetchChallengeBucketBlueprints());
    dispatch(fetchChallengeTrackBlueprints());
    dispatch(fetchTopicBlueprints());
    dispatch(fetchSectionBlueprints());
    dispatch(fetchTopicSections());
  };
}
