import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from 'images/logo.png';

class SideNav extends React.Component {
  renderMainSideNav() {
    const { path } = this.props;
    return (
      // <div className="sideNavAdmin">
      //   <div className="sideNavAdmin__content">
      //     {this.closeMenuButton()}
      //     <ul>
      //       <li>
      //         <Link to={'/'}>
      //           <button
      //             className={
      //               window.location.pathname === '/'
      //                 ? 'sideNavAdmin__btn--selected'
      //                 : 'sideNavAdmin__btn'
      //             }
      //           >
      //             <p>Dashboard</p>
      //           </button>
      //         </Link>
      //       </li>
      //       <li>
      //         <Link to={'/accounts'}>
      //           <button
      //             className={
      //               window.location.pathname.includes('/admin/accounts')
      //                 ? 'sideNavAdmin__btn--selected'
      //                 : 'sideNavAdmin__btn'
      //             }
      //           >
      //             <p>Accounts</p>
      //           </button>
      //         </Link>
      //       </li>

      //       <li>
      //         <Link to={'/app-config'}>
      //           <button
      //             className={
      //               window.location.pathname.includes('/app-config')
      //                 ? 'sideNavAdmin__btn--selected'
      //                 : 'sideNavAdmin__btn'
      //             }
      //           >
      //             <p>App Config</p>
      //           </button>
      //         </Link>
      //       </li>

      //       <li>
      //         <Link to={'/app-data'}>
      //           <button
      //             className={
      //               window.location.pathname.includes('/app-data')
      //                 ? 'sideNavAdmin__btn--selected'
      //                 : 'sideNavAdmin__btn'
      //             }
      //           >
      //             <p>App Data</p>
      //           </button>
      //         </Link>
      //       </li>

      //       <li>
      //         <Link to={'/builder-manager'}>
      //           <button
      //             className={
      //               window.location.pathname.includes('/builder-manager')
      //                 ? 'sideNavAdmin__btn--selected'
      //                 : 'sideNavAdmin__btn'
      //             }
      //           >
      //             <p>Builder Manager</p>
      //           </button>
      //         </Link>
      //       </li>

      //       <li>
      //         <Link to={'/hubs'}>
      //           <button
      //             className={
      //               window.location.pathname.includes('/hubs')
      //                 ? 'sideNavAdmin__btn--selected'
      //                 : 'sideNavAdmin__btn'
      //             }
      //           >
      //             <p>Hubs</p>
      //           </button>
      //         </Link>
      //       </li>

      //       <li>
      //         <Link to={'/projects'}>
      //           <button
      //             className={
      //               window.location.pathname.includes('/projects')
      //                 ? 'sideNavAdmin__btn--selected'
      //                 : 'sideNavAdmin__btn'
      //             }
      //           >
      //             <p>Projects</p>
      //           </button>
      //         </Link>
      //       </li>

      //       {/* {project.length > 0 &&
      //         <li>
      //           <button onClick={() => showProjects()} className=" sideNavAdmin__btn">
      //             <img src={require("/icon_user-project.png")} />
      //             <p>Projects ></p>
      //           </button>
      //         </li>
      //       }
      //       <li>
      //         <Link to="/create-project"><button className={project.length > 0 ? ' sideNavAdmin__btn' : ' sideNavAdmin__btn'} >
      //           <p>Start a project</p>
      //         </button>
      //         </Link>
      //       </li>
      //       <li>
      //         <Link to="/browse">
      //           <button className=" sideNavAdmin__btn">
      //             <p>Browse projects</p>
      //           </button>
      //         </Link>
      //       </li> */}
      //     </ul>
      //   </div>
      //   <div className="sideNavAdmin__logo" />
      // </div>
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

        <Link to={'/'} className="sidebar-brand d-flex align-items-center justify-content-center" >
          <div className="sidebar-brand-icon rotate-n-15">
            {/* <i className="fas fa-laugh-wink"></i> */}
          </div>
          <div className="sidebar-brand-text mx-3">
            <img className="img-fluid " src={logo} />
          </div>
        </Link>

        <hr className="sidebar-divider my-0" />

        <li className={
          path === '/'
            ? 'nav-item active'
            : 'nav-item'
        }>
          <Link to={'/'} className="nav-link">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">
          User Data
        </div>

        <li className={
          path.includes('/accounts')
            ? 'nav-item active'
            : 'nav-item'
        }>
          <Link to={'/accounts'} className="nav-link">
            <i className="fas fa-fw fa-user"></i>
            <span>Accounts</span>
          </Link>
        </li>

        <li className={
          path.includes('/projects')
            ? 'nav-item active'
            : 'nav-item'
        }>
          <Link to={'/projects'} className="nav-link">
            <i className="fas fa-fw fa-table"></i>
            <span>Projects</span>
          </Link>
        </li>

        <li className={
          path.includes('/customers')
            ? 'nav-item active'
            : 'nav-item'
        }>
          <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCustomer" aria-expanded="true" aria-controls="collapseCustomer">
            <i className="fas fa-fw fa-table"></i>
            <span>Customers</span>
          </a>
          <div id="collapseCustomer" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to={'/customers'} className="collapse-item">Customer List</Link>
              <Link to={'/customers/add'} className="collapse-item">Add Customer</Link>
            </div>
          </div>
        </li>

        <li className={
          path.includes('/hubs')
            ? 'nav-item active'
            : 'nav-item'
        }>
          <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseHub" aria-expanded="true" aria-controls="collapseHub">
            <i className="fas fa-fw fa-table"></i>
            <span>Hubs</span>
          </a>
          <div id="collapseHub" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to={'/hubs'} className="collapse-item">Hub List</Link>
              <Link to={'/hubs/invitations'} className="collapse-item">Hub Invitations</Link>
            </div>
          </div>
        </li>
        
        <hr className="sidebar-divider" />

      

  
        <div className="sidebar-heading">
          Application Data
        </div>

        <li className={
          path.includes('/builder-manager')
            ? 'nav-item active'
            : 'nav-item'
        }>
          <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBuilder" aria-expanded="true" aria-controls="collapseBuilder">
            <i className="fas fa-fw fa-cog"></i>
            <span>Builders</span>
          </a>
          <div id="collapseBuilder" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              <Link to={'/builder-manager'} className="collapse-item">Builder Manager</Link>
              <Link to={'/builder-manager/sections'} className="collapse-item">Sections</Link>
            </div>
          </div>
        </li>

        <hr className="sidebar-divider d-none d-md-block" />
        {/* 
        <div className="text-center d-none d-md-inline">
          <button className="rounded-circle border-0" id="sidebarToggle"></button>
        </div> */}

      </ul>
    );
  }

  render() {
    return this.renderMainSideNav();
  }
}

const mapDispatchToProps = () => ({

});

function mapStateToProps() {
  const path = window.location.pathname;
  return {
    path,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
