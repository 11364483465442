import React from 'react';
import ReactDOM, { render } from 'react-dom';
import { configureStore, history } from './store/configureStore';
import ErrorBoundary from './components/ErrorBoundary';

import App from './App';

const store = configureStore();

ReactDOM.render(
  <ErrorBoundary>
    <App store={store} history={history} />
  </ErrorBoundary>,

  document.getElementById('root')
);
