import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import QuillField from '../../../shared/quill/quillField';
import loader from 'images/loader.gif';
import {
  addTopicBlueprint,
  updateTopicBlueprint,
} from '../../../store/builderManager/builderManagerActions';

import '../builderManager.scss';
import { RESET_REDIRECT } from '../../../store/metadata/metadataActions';

class TopicBlueprintForm extends React.Component {
  renderForm() {
    const { initialValues, isProcessing } = this.props;
    return (
      <div>

        <div className="question__wrap">
          <p className="question">Sort Order</p>
          <div className="question__option">
            <Field type="number" component="input" name="sort_order" />
          </div>
        </div>

        <section className="[Introduction]">
          <h3 className="headthree">English Copy</h3>

          <div className="question__wrap">
            <p className="question required">Title</p>
            <div className="question__option">
              <Field
                type="text"
                component="input"
                name="title"
                required
              />
            </div>
          </div>

          <div className="question__wrap">
            <p className="question required">Question</p>
            <div className="question__option">
              <Field
                type="text"
                component="textarea"
                name="question"
                placeholder="i.e. What did you do to feel stellar today?"
                required
              />
            </div>
          </div>


          <div className="question__wrap">
            <p className="question">Tip</p>
            <div className="question__option">
              <Field
                type="text"
                component="textarea"
                name="tip"
                placeholder="i.e. Housekeeping Tip: Always keep several get well cards on the mantel. That way, if unexpected guests arrive, they will think you've been sick and unable to clean."
              />
            </div>
          </div>

          <div className="question__wrap">
            <p className="question required">Topic Introduction Information</p>
            <div className="question__option">
              <QuillField
                name="introduction_delta"
                className="question__quill"
                delta={initialValues ? initialValues.introduction_delta : null}
              />
            </div>
          </div>
        </section>


        <section className="[Introduction]">
          <h3 className="headthree">Spanish Copy</h3>

          <div className="question__wrap">
            <p className="question required">Title</p>
            <div className="question__option">
              <Field
                type="text"
                component="input"
                name="title_spanish"
                required
              />
            </div>
          </div>

          <div className="question__wrap">
            <p className="question required">Question</p>
            <div className="question__option">
              <Field
                type="text"
                component="textarea"
                name="question_spanish"
                placeholder="i.e. What did you do to feel stellar today?"
                required
              />
            </div>
          </div>
          <div className="question__wrap">
            <p className="question">Tip</p>
            <div className="question__option">
              <Field
                type="text"
                component="textarea"
                name="tip_spanish"
                placeholder="i.e. Housekeeping Tip: Always keep several get well cards on the mantel. That way, if unexpected guests arrive, they will think you've been sick and unable to clean."
              />
            </div>
          </div>

          <div className="question__wrap">
            <p className="question required">Topic Introduction Information</p>
            <div className="question__option">
              <QuillField
                name="introduction_delta_spanish"
                className="question__quill"
                delta={initialValues ? initialValues.introduction_delta_spanish : null}
              />
            </div>
          </div>
        </section>

        {/* <h3 className="headthree">Community Help</h3>
        <div className="question__wrap">
          <p className="question">Examples</p>
          <div className="question__option">
            <QuillField
              name="examples_delta"
              delta={initialValues ? initialValues.examples_delta : null}
            />
            {/* <Field
              type="text"
              component="textarea"
              name="examples"
              placeholder="add examples that helps people visualize how to answer the question"
            /> 
          </div>
        </div>
        <div className="question__wrap">
          <p className="question">Resources</p>
          <div className="question__option">
            <QuillField
              name="resources_delta"
              delta={initialValues ? initialValues.resources_delta : null}
            />
            <Field
              type="text"
              component="textarea"
              name="resources"
              placeholder="put outside resources here... links or blurbs with links will do."
            /> 
          </div>
          </div>*/}
        {isProcessing ? (
          <button className="btn-save btn-disabled btn-right" disabled>
            <img src={loader} alt="loading" /> saving...
          </button>
        ) : (
            <button type="submit" className="btn-save btn-right">
              save
            </button>
          )}
      </div>
    );
  }

  renderNewTopic() {
    const { create, match } = this.props;
    return (
      <div className=" admin">
        <div className="[subject_edit]">
          {/* <div className="bread-crumbs">
            <Link to="/builder-manager">Builder Manager </Link> > <Link to={`/builder-manager/${match.params.cbb_id}`}>Track One - Ideation</Link>  > <Link to={`/builder-manager/${match.params.cbb_id}/${match.params.cb_id}`}>Name Builder</Link> > New Topic
          </div> */}
          <h2 className="headtwo">New Topic</h2>
          <form
            onSubmit={(e) =>
              create(
                e,
                match.params.cb_id,
                `/builder-manager/tracks/${match.params.ctb_id}/builders/${match.params.cb_id}`
              )
            }
          >
            {this.renderForm()}
          </form>
        </div>
      </div>
    );
  }

  renderEditTopic() {
    const { edit, match } = this.props;
    return (
      <div className=" admin">
        <div className="[subject_edit]">
          {/* <div className="bread-crumbs">
            <Link to="/builder-manager">Builder Manager </Link> > <Link to={`/builder-manager/${match.params.cbb_id}`}>Track One - Ideation</Link>  > <Link to={`/builder-manager/${match.params.cbb_id}/${match.params.cb_id}`}>Name Builder</Link> > <Link to={`/builder-manager/${match.params.cbb_id}/${match.params.cb_id}/${match.params.tb_id}`}> Name Topic</Link> > Edit Topic
          </div> */}
          <h2 className="headtwo">Edit Topic</h2>
          <form onSubmit={(e) => edit(e, match.params.tb_id)}>
            {this.renderForm()}
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { match, redirect, close } = this.props;

    // if save sucessful, redirect
    if (redirect) {
      close(); // close the modal
    }

    // check if editing a topic
    if (match.params.tb_id) {
      return this.renderEditTopic();
    }

    return this.renderNewTopic();
  }
}

const mapDispatchToProps = (dispatch) => ({
  close() {
    dispatch({ type: RESET_REDIRECT }); // prevent redirect
    window.history.back();
  },
  create(event, cbId, redirectTo) {
    event.preventDefault();
    dispatch(addTopicBlueprint(cbId, redirectTo));
  },
  edit(event, tbId) {
    event.preventDefault();
    dispatch(updateTopicBlueprint(tbId));
  },
});

const mapStateToProps = (state, props) => {
  // if the currently editing an existing challenge bucket blueprint, get the existing values
  const topicBlueprint = state.challengeBuilder.topicBlueprints.find(
    (tb) => tb.get('id').toString() === props.match.params.tb_id
  );

  // set inital form values
  const initialValues = topicBlueprint
    ? {
      title: topicBlueprint.get('title'),
      question: topicBlueprint.get('question'),
      tip: topicBlueprint.get('tip'),
      introduction_delta: topicBlueprint.get('introduction_delta'),
      title_spanish: topicBlueprint.get('title_spanish'),
      question_spanish: topicBlueprint.get('question_spanish'),
      tip_spanish: topicBlueprint.get('tip_spanish'),
      introduction_delta_spanish: topicBlueprint.get('introduction_delta_spanish'),
      sort_order: topicBlueprint.get('sort_order'),
    }
    : null;

  const redirect = state.metadata.redirect;
  return {
    redirect,
    initialValues,
    isProcessing: state.metadata.isProcessing,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'TopicBlueprintForm', enableReinitialize: true })(
    TopicBlueprintForm
  )
);
