import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RESET_REDIRECT } from '../../store/metadata/metadataActions';

class DoRedirect extends React.Component {
  render() {
    const { redirectTo, resetRedirect } = this.props;

    if (redirectTo) {
      resetRedirect();
      return (<Redirect to={redirectTo} />);
    }
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  resetRedirect() {
    dispatch({ type: RESET_REDIRECT });
  },
});

function mapStateToProps(state) {
  const { redirectTo } = state.metadata;
  return {
    redirectTo,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoRedirect);
