import React, { useState, useEffect } from 'react';
import Confirm from 'views/hubs/components/multistepForm/Confirm';
import FirstStep from 'views/hubs/components/multistepForm/FirstStep';
import SecondStep from 'views/hubs/components/multistepForm/SecondStep';
import { useDispatch, useSelector } from 'react-redux';
import {
  createHubInvitationStep,
  resetCreateHubInvitation,
} from 'store/hubs/hubActions';

const HubInvitation: React.FC = () => {
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const formValues = useSelector(
    (state) => (state as any).hubs.createHubInvitation
  );

  const updateStep = (val: 1 | -1) => {
    setStep(((step + val) % 3) as 0 | 1 | 2);
  };

  const increaseStep = () => updateStep(1);
  const decreaseStep = () => updateStep(-1);

  const onSuccessCb = () => {
    increaseStep();
    setSubmitting(false);
  };

  const nextStep = (values: any) => {
    dispatch(createHubInvitationStep(step, values, onSuccessCb));
    if (step < 2) increaseStep();
    else setSubmitting(true);
  };

  const cancel = () => {
    dispatch(resetCreateHubInvitation());
    setStep(0);
  };

  useEffect(() => cancel(), []);

  const renderStep = (): React.ReactChild => {
    if (step === 1)
      return (
        <SecondStep
          prevStep={decreaseStep}
          onSubmit={nextStep}
          initialValues={formValues}
        />
      );
    if (step === 2)
      return (
        <Confirm
          prevStep={decreaseStep}
          onSubmit={nextStep}
          isSubmitting={isSubmitting}
        />
      );
    return <FirstStep onSubmit={nextStep} initialValues={{ ...formValues }} />;
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h4 className="m-0 font-weight-bold text-primary">
              Send a Hub Invitation
            </h4>
          </div>
          <div className="card-body">
            {renderStep()}
            {step !== 0 && (
              <div className="col-lg-4 mt-3">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HubInvitation;
