import React from 'react';
import moment from 'moment';
import { show, success, error, warning, info, hide, removeAll } from 'react-notification-system-redux';

export function defaultPopup() { }

/*
KEY         TYPE            DEFAULT
title	      string	        null	    Title of the notification
message	    string	        null	    Message of the notification
level	      string	        null	    Level of the notification. Available: success, error, warning and info
position	  string	        tr	      Position of the notification. Available: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center)
autoDismiss	integer	        5	        Delay in seconds for the notification go away. Set this to 0 to not auto-dismiss the notification
dismissible	bool	          true	    Set if notification is dismissible by the user. See more
action	    object	        null	    Add a button with label and callback function (callback is optional). See more
children	  element,string	null	    Adds custom content, and overrides action (if defined) See more
onAdd	      function	      null	    A callback function that will be called when the notification is successfully added. The first argument is the original notification e.g. function (notification) { console.log(notification.title + 'was added'); }
onRemove	  function	      null	    A callback function that will be called when the notification is about to be removed. The first argument is the original notification e.g. function (notification) { console.log(notification.title + 'was removed'); }
uid	        integer/string	null	    Overrides the internal uid. Useful if you are managing your notifications id. Notifications with same uid won't be displayed.
More info: 
  https://github.com/gor181/react-notification-system-redux
  https://github.com/igorprado/react-notification-system
*/

// popup(message, level, title, position, autoDismiss, dismissable, children, uid)
export function popup(
  message = null,
  level,
  title = null,
  position = 'tr',
  autoDismiss = 0,
  dismissable = true,
  children = null,
  uid = null,
) {
  return (dispatch) => {
    const notificationOptions = {
      uid,
      title,
      position,
      autoDismiss,
      dismissable,
      // moment() gets the current client time
      // format() sets the time string into desired format. refer moment.js
      children: (
        <div>
          <span className="popUp__timeStamp">{moment().format('h:mm a')}</span>
          <p className="popUp__note">{message}</p>
          {children}
        </div>
      ),
    };

    switch (level) {
      case 'success':
        return dispatch(success(notificationOptions));
      case 'warning':
        return dispatch(warning(notificationOptions));
      case 'error':
        return dispatch(error(notificationOptions));
      case 'info':
        return dispatch(info(notificationOptions));
      default:
        return defaultPopup(message, position);
    }
  };
}

// remove all popups
export function removePopups() {
  return (dispatch) => {
    dispatch(removeAll());
  };
}
