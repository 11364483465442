import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import iconSearch from 'images/icon_search.png';
function sectionTypeLabel(sectionType) {
  switch (sectionType) {
    case 'workshop':
      return 'workshop';
    case 'brief':
      return 'brief';
    case 'inspiration':
      return 'inspir. byte';
    default:
      return 'section';
  }
}

class SectionBlueprints extends Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: '', searchType: 'all' };
  }

  render() {
    const { sectionBlueprints } = this.props;

    let filteredSectionBlueprints = sectionBlueprints;

    if (this.state.searchValue) {
      filteredSectionBlueprints = filteredSectionBlueprints.filter((fsb) =>
        fsb.title.toLowerCase().includes(this.state.searchValue)
      );
    }

    switch (this.state.searchType) {
      case 'briefs':
        filteredSectionBlueprints = filteredSectionBlueprints.filter(
          (fsb) => fsb.type === 'brief'
        );
        break;
      case 'workshops':
        filteredSectionBlueprints = filteredSectionBlueprints.filter(
          (fsb) => fsb.type === 'workshop'
        );
        break;
      case 'inspirations':
        filteredSectionBlueprints = filteredSectionBlueprints.filter(
          (fsb) => fsb.type === 'inspiration'
        );
        break;
      case 'sections':
        filteredSectionBlueprints = filteredSectionBlueprints.filter(
          (fsb) => fsb.type === 'section' || !fsb.type
        );
        break;
      default:
        break;
    }

    return (
      <div className="container-fluid admin">
        <Link to="/builder-manager">&lt; back to Builder Manager</Link>
        <div>
          <h3 className="headthree">ALL SECTIONS</h3>
          <Link to="sections/add">
            <button className="btn-right">+ New Section</button>
          </Link>

          <form
            className="section__searchbox"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              className="section__input"
              id="search"
              onChange={(input) =>
                this.setState({ searchValue: input.target.value })
              }
            />
            <button className="section__searchbox__btn">
              <img src={iconSearch} />
            </button>
          </form>

          <div className="section__filters">
            <span className="section__filters__title">filters:</span>
            <button
              className={
                this.state.searchType === 'all'
                  ? 'section__filters__btn--selected'
                  : 'section__filters__btn'
              }
              onClick={() => this.setState({ searchType: 'all' })}
            >
              all
            </button>
            <button
              className={
                this.state.searchType === 'briefs'
                  ? 'section__filters__btn--selected'
                  : 'section__filters__btn'
              }
              onClick={() => this.setState({ searchType: 'briefs' })}
            >
              briefs
            </button>
            <button
              className={
                this.state.searchType === 'inspirations'
                  ? 'section__filters__btn--selected'
                  : 'section__filters__btn'
              }
              onClick={() => this.setState({ searchType: 'inspirations' })}
            >
              inspiration bytes
            </button>
            <button
              className={
                this.state.searchType === 'workshops'
                  ? 'section__filters__btn--selected'
                  : 'section__filters__btn'
              }
              onClick={() => this.setState({ searchType: 'workshops' })}
            >
              workshop
            </button>
            <button
              className={
                this.state.searchType === 'sections'
                  ? 'section__filters__btn--selected'
                  : 'section__filters__btn'
              }
              onClick={() => this.setState({ searchType: 'sections' })}
            >
              sections (unclassified)
            </button>
          </div>

          {filteredSectionBlueprints.length > 0 ? (
            <ul className="display__layout">
              {filteredSectionBlueprints.map((fsb) => (
                <li>
                  <div className="section__list--left">
                    <span className="section__type">
                      {sectionTypeLabel(fsb.type)}
                    </span>
                    <span>{fsb.title}</span>
                  </div>
                  <Link to={`/builder-manager/sections/${fsb.id}/edit`}>></Link>
                </li>
              ))}
            </ul>
          ) : (
            <span>No sections</span>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { sectionBlueprints } = state.challengeBuilder;

  return {
    sectionBlueprints: sectionBlueprints.toJS(),
  };
};

export default connect(mapStateToProps)(SectionBlueprints);
