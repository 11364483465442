
  
import { combineReducers } from 'redux';
import { List, fromJS } from 'immutable';
import { SET_CUSTOMERS, SET_CUSTOMER } from './customerActions';

function customers(state = List(), action) {
  switch (action.type) {
    case SET_CUSTOMERS:
      return List(fromJS(action.data));
    case SET_CUSTOMER:
      return state.push(fromJS(action.data));
    default:
      return state;
  }
}




 export default combineReducers({
   customers
});