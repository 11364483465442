export const SHOW_SIDE_NAV = 'SHOW_SIDE_NAV';
export const HIDE_SIDE_NAV = 'HIDE_SIDE_NAV';

export function showSideNav() {
  return {
    type: SHOW_SIDE_NAV,
  };
}

export function hideSideNav() {
  return {
    type: HIDE_SIDE_NAV,
  };
}
