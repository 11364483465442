import React from 'react';
import { Formik, Form, Field } from 'formik';

interface Props {
  handleSubmit: (values: any) => void;
  secondaryBtnAction: React.MouseEventHandler<HTMLButtonElement>;
  primaryBtnLabel: string | React.ReactChild;
  secondaryBtnLabel: string;
  isPrimaryBtnDisabled?: boolean;
  initialValues: {
    [key: string]: string | number;
  };
}

const HubLimitsForm: React.FC<Props> = ({
  handleSubmit,
  secondaryBtnAction,
  primaryBtnLabel,
  secondaryBtnLabel,
  isPrimaryBtnDisabled = false,
  initialValues,
}) => {
  const renderFormField = (label: string, name: string, isRequired = true) => (
    <div className="form-group row">
      <label htmlFor={name} className="col-sm-2 col-form-label">
        {`${label}:${isRequired && "*"} `}
      </label>
      <div className="col-sm-6">
        <Field
          type="number"
          name={name}
          min="-1"
          className="form-control"
          required={isRequired}
        />
      </div>
    </div>
  );

  return (
    <div>
      <p>*Fill in -1 to set limit as Unlimited</p>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        <Form>
          {renderFormField(
            'Max number of active groups',
            'max_number_of_active_groups'
          )}
          {renderFormField(
            'Max number of events per year',
            'max_number_of_events_per_year'
          )}
          {renderFormField(
            'Max number of projects per event',
            'max_number_of_projects_per_event'
          )}
          {renderFormField(
            'Max number of hub admins',
            'max_number_of_hub_admins'
          )}
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginRight: '1rem' }}
            onClick={secondaryBtnAction}
          >
            {secondaryBtnLabel}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isPrimaryBtnDisabled}
          >
            {primaryBtnLabel}
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default HubLimitsForm;
