import { List, fromJS } from 'immutable';
import { SET_USERS } from './accountActions';


const initAccounts = List();
function users(state = initAccounts, action) {
  switch (action.type) {
    case SET_USERS:
      return state.concat(fromJS(action.data));
    default:
      return state;
  }
}


export default users;
