import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { publishTopicBlueprint } from '../../../store/builderManager/builderManagerActions';
import loader from 'images/loader.gif';

const TopicTile = ({ tb, challengeBlueprint, ctbId, cbId, publish }) => {
  const [isProcessing, setProcessing] = useState(false);
  return (
    <li key={`cb-${tb.id}`}>
      <p>
        {tb.title}.{' '}
        <span className="builder-view__question">{tb.question}</span>
      </p>
      {!tb.is_published && challengeBlueprint.is_published && (
        <button
          onClick={() => {
            setProcessing(true);
            publish(
              tb.id,
              () => setProcessing(false),
              () => setProcessing(false)
            );
          }}
          disabled={isProcessing}
        >
          {!isProcessing ? 'Publish' : <img src={loader} />}
        </button>
      )}
      <Link
        to={`/builder-manager/tracks/${ctbId}/builders/${cbId}/topics/${tb.id}`}
      >
        <p className="display__subtitle">
          <span className="display__subtitle--click">&gt;</span>
        </p>
      </Link>
    </li>
  );
};

const BuilderBlueprint = ({
  challengeBlueprint,
  match,
  topicBlueprints,
  publish,
  isProcessing,
}) => (
  <div className="container-fluid admin">
    <div className="[challenge]">
      <div className="bread-crumbs">
        <Link to="/builder-manager">Builder Manager</Link> &gt;{' '}
        <Link to={`/builder-manager/tracks/${match.params.ctb_id}`}>Track</Link>{' '}
        &gt; {challengeBlueprint.title} Builder
      </div>

      <div className="title__wrap">
        <div>
          <h3 className="headthree">Builder</h3>
          <h2>{challengeBlueprint.title}</h2>
          <img
            className="builder-view__cover-img"
            src={challengeBlueprint.cover_img_url}
          />
        </div>
        <Link
          to={`/builder-manager/tracks/${match.params.ctb_id}/builders/${match.params.cb_id}/edit`}
        >
          <span className="edit-link">edit</span>
        </Link>
      </div>

      <h3 className="headthree">Topics</h3>
      <Link
        to={`/builder-manager/tracks/${match.params.ctb_id}/builders/${match.params.cb_id}/topics/add`}
      >
        <button className="btn-right">+ New Topic</button>
      </Link>
      {topicBlueprints.length ? (
        <ul className="display__layout">
          {topicBlueprints.map((tb) => (
            <TopicTile
              key={`tb-${tb.id}`}
              tb={tb}
              challengeBlueprint={challengeBlueprint}
              ctbId={match.params.ctb_id}
              cbId={match.params.cb_id}
              publish={publish}
            />
          ))}
        </ul>
      ) : (
        <div>No topics</div>
      )}
    </div>
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  publish(tbId, onSuccessCb, onErrorCb) {
    dispatch(publishTopicBlueprint(tbId, onSuccessCb, onErrorCb));
  },
});
const mapStateToProps = (state, props) => {
  // get challenge blueprint info
  const challengeBlueprint = state.challengeBuilder.challengeBlueprints.find(
    (cb) => cb.get('id').toString() === props.match.params.cb_id
  );

  // get all challenge blueprints that belong to this challenge bucket blueprint
  const topicBlueprints = state.challengeBuilder.topicBlueprints
    .filter(
      (tb) =>
        tb.get('challenge_blueprint_id').toString() === props.match.params.cb_id
    )
    .sortBy((tb) => tb.get('sort_order'))
    .toJS();

  return {
    challengeBlueprint: challengeBlueprint
      ? challengeBlueprint.toJS()
      : { title: null, is_published: false },
    topicBlueprints,
    isProcessing: state.metadata.isProcessing,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderBlueprint);
