import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import '../../css/tables.scss';
import { formatDate } from '../../utils/functions';

const ProjectList = ({ projects }) => (
  <div>
    <div>
      <div>
        <form>
          <Field
            type="text"
            component="input"
            placeholder="Search"
            name="search"
          />
          <br />
        </form>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th />
            <th>Name </th>
            <th>Id</th>
            <th>Inserted At</th>
            <th>Is Active</th>
            <th>Is Public</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{project.name}</td>
                <td>{project.id}</td>
                <td>{formatDate(project.inserted_at)}</td>
                <td>{project.is_active ? 'yes' : 'no'}</td>
                <td>{project.is_public ? 'yes' : 'no'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
);

function mapStateToProps(state) {
  const { projects, form } = state;
  const serachString =
    form.projectListForm && form.projectListForm.values
      ? form.projectListForm.values.search.toLowerCase()
      : null;
  const filterprojectList = serachString
    ? projects.filter(project =>
        project
          .get('name')
          .toLowerCase()
          .includes(serachString)
      )
    : projects;
  return {
    projects: filterprojectList
      .sortBy(project => project.get('inserted_at'))
      .toJS()
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'projectListForm',
    enableReinitialize: true
  })(ProjectList)
);
