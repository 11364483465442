import fetch from 'isomorphic-fetch';
import moment from 'moment';

// API REQUESTS
export const request = (apiUrl, jwt = null, method = 'GET', body = null) => {
  return new Promise((resolve, reject) => {
    const payload = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    if (body !== null) {
      payload.body = JSON.stringify(body);
    }

    return fetch(apiUrl, payload)
      .then(
        (response) => {
          if (response.ok) {
            switch (response.status) {
              case 202: // accepted
                resolve(response);
                return;
              case 204: // delete
                resolve(response);
                return; // return after resolve/reject is a failsafe for future traps
              default:
                resolve(response.json());
                return;
            }
          }

          switch (response.status) {
            case 422: // unprocessable entity
              resolve(response);
              return;
            default:
              reject(response);
          }
        },
        (error) => {
          reject(Error(error));
        }
      )
      .catch((error) => reject(Error(error)));
  });
};

// UPLOAD IMAGE
export const postImage = (apiUrl, jwt = null, method = 'GET', body = null) => {
  return new Promise((resolve, reject) => {
    const payload = {
      method,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    };
    if (body !== null) {
      payload.body = JSON.stringify(body);
    }

    return fetch(apiUrl, {
      method,
      credentials: 'same-origin',
      headers: {
        // 'x-csrf-admin_token': window.csrfadmin_token,
        Authorization: `Bearer ${jwt}`,
        Accept:
          'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
      },
      body,
    }).then(
      (response) => {
        if (response.ok) {
          switch (response.status) {
            case 204: // delete
              resolve(response);
              return; // return after resolve/reject is a failsafe for future traps
            case 200:
              resolve(response.json());
              return;
            default:
              resolve(response);
              return;
          }
        }
        reject(`${response.url} ${response.status} (${response.statusText})`);
      },
      (error) => {
        reject(Error(error));
      }
    );
  });
};

// FORMATTING DATE TIME
export function formatDate(date) {
  // store time as moment.js array after converting from UTC to local time.
  const inputDate = moment.utc(date).locale('en').local();
  // check if date is today or yesterday and return respective string format
  if (inputDate.isSame(moment(), 'day')) {
    return inputDate.format('[today at] h:mm a');
  } else if (inputDate.isSame(moment().subtract(1, 'day'), 'day')) {
    return inputDate.format('[yesterday at] h:mm a');
  }
  return inputDate.format('MMM DD YYYY [at] h:mm a');
}

// VALIDATION
export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateURL(url) {
  const re =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  return re.test(String(url));
}

// CHECK IF DEVICE IS MOBILE
export function isMobileDevice() {
  let isMobile = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }
  if (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  ) {
    isMobile = true;
  }
  return isMobile;
}

export function getBrowserInfo() {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
}

/**
 * JavaScript Client Detection
 * (C) viazenetti GmbH (Christian Ludwig)
 */
export function getSystemInfo() {
  let unknown = '-';

  // screen
  // let screenSize = '';
  // if (screen.width) {
  //   let width = screen.width ? screen.width : '';
  //   let height = screen.height ? screen.height : '';
  //   screenSize += '' + width + ' x ' + height;
  // }

  // browser
  let nVer = navigator.appVersion;
  let nAgt = navigator.userAgent;
  let browser = navigator.appName;
  let version = '' + parseFloat(navigator.appVersion);
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset, verOffset, ix;

  // Opera
  if ((verOffset = nAgt.indexOf('Opera')) != -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) != -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Opera Next
  if ((verOffset = nAgt.indexOf('OPR')) != -1) {
    browser = 'Opera';
    version = nAgt.substring(verOffset + 4);
  }
  // Edge
  else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
    browser = 'Microsoft Edge';
    version = nAgt.substring(verOffset + 5);
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(verOffset + 5);
  }
  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    browser = 'Chrome';
    version = nAgt.substring(verOffset + 7);
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browser = 'Safari';
    version = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) != -1) {
      version = nAgt.substring(verOffset + 8);
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    browser = 'Firefox';
    version = nAgt.substring(verOffset + 8);
  }
  // MSIE 11+
  else if (nAgt.indexOf('Trident/') != -1) {
    browser = 'Microsoft Internet Explorer';
    version = nAgt.substring(nAgt.indexOf('rv:') + 3);
  }
  // Other browsers
  else if (
    (nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))
  ) {
    browser = nAgt.substring(nameOffset, verOffset);
    version = nAgt.substring(verOffset + 1);
    if (browser.toLowerCase() == browser.toUpperCase()) {
      browser = navigator.appName;
    }
  }
  // trim the version string
  if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
  if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

  majorVersion = parseInt('' + version, 10);
  if (isNaN(majorVersion)) {
    version = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }

  // mobile version
  let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

  // cookie
  let cookieEnabled = navigator.cookieEnabled ? true : false;

  if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') != -1 ? true : false;
  }

  // system
  let os = unknown;
  let clientStrings = [
    { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Linux', r: /(Linux|X11)/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    {
      s: 'Search Bot',
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (let id in clientStrings) {
    let cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  let osVersion = unknown;

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = 'Windows';
  }

  switch (os) {
    case 'Mac OS X':
      osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'Android':
      osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
      break;

    case 'iOS':
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
      break;
  }

  // flash (you'll need to include swfobject)
  /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
  // let flashVersion = 'no check';
  // if (typeof swfobject != 'undefined') {
  //   let fv = swfobject.getFlashPlayerVersion();
  //   if (fv.major > 0) {
  //     flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
  //   } else {
  //     flashVersion = unknown;
  //   }
  // }

  return {
    // screen: screenSize,
    browser: browser,
    browserVersion: version,
    browserMajorVersion: majorVersion,
    mobile: mobile,
    os: os,
    osVersion: osVersion,
    cookies: cookieEnabled,
    // flashVersion: flashVersion,
  };
}

export function isUnsupportedClient() {
  let browser = navigator.appName;

  if (browser === 'Microsoft Internet Explorer') {
    return true;
  }
  return false;
}

// alert(
//   'OS: ' + jscd.os +' '+ jscd.osVersion + '\n' +
//   'Browser: ' + jscd.browser +' '+ jscd.browserMajorVersion +
//     ' (' + jscd.browserVersion + ')\n' +
//   'Mobile: ' + jscd.mobile + '\n' +
//   'Flash: ' + jscd.flashVersion + '\n' +
//   'Cookies: ' + jscd.cookies + '\n' +
//   'Screen Size: ' + jscd.screen + '\n\n' +
//   'Full User Agent: ' + navigator.userAgent
// );

// check if the current page is a public page
export function checkPublicPath(path) {
  switch (path) {
    case '/':
    case '/users-video':
    case '/login':
    case '/signup':
    case '/builders':
    case '/about':
    case '/whyrebelbase':
    case '/how-it-works':
      return true;
    default:
      return false;
  }
}

// check if a path is a modal or not
export function checkModalPath(path) {
  return (
    path.includes('/login') ||
    path.includes('/signup') ||
    path.includes('/settings') ||
    path.includes('/contact-us') ||
    path.includes('/tell-us') ||
    path.includes('/avatar-upload') ||
    path.includes('/way-find') ||
    path.includes('/help') ||
    path.includes('/create-hub/') ||
    path.includes('invite-teammate') ||
    path.includes('/create-event') ||
    path.includes('invite') ||
    path.includes('manage-participants') ||
    path.includes('invite-participants') ||
    path.endsWith('/edit-event') ||
    path.endsWith('/edit-hub') ||
    path.endsWith('/invite-members') ||
    path.endsWith('/pending-invitations')
  );
}

// check if a word or pharse starts with a vowel
export function doesStartWithVowel(string) {
  return 'aeiou'.indexOf(string.charAt(0).toLowerCase()) >= 0;
}

// Converting image to base64
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// Email Text To List
// - takes emails in text format which are seperated by comma or space and returns a list of emails
// - validates the emails and returns each email as a map [email: string, valid: boolean]
export function emailTextToList(text) {
  if (!!text === false || text.trim() === '') return [];
  const emailText = text.trim();
  const emailList = emailText
    .replace(/\n/, ',')
    .split(/[ ,]+/)
    .map((email) => {
      return { email: email.toLowerCase(), valid: validateEmail(email) };
    });
  return emailList;
}

// convert int to numberWord (example: 1 to one)
export function convertNumberToNumberWord(number) {
  switch (number) {
    case 0:
      return 'zero';
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    case 6:
      return 'six';
    case 7:
      return 'seven';
    case 8:
      return 'eight';
    case 9:
      return 'nine';
    case 10:
      return 'ten';
    case 11:
      return 'eleven';
    case 12:
      return 'twelve';
    case 13:
      return 'thirteen';
    case 14:
      return 'fourteen';
    case 15:
      return 'fifteen';
    case 16:
      return 'sixteen';
    case 17:
      return 'seventeen';
    case 18:
      return 'eighteen';
    case 19:
      return 'nineteen';
    case 20:
      return 'twenty';
    case 30:
      return 'thirty';
    case 40:
      return 'fourty';
    case 50:
      return 'fifty';
    case 60:
      return 'sixty';
    case 70:
      return 'seventy';
    case 80:
      return 'eighty';
    case 90:
      return 'ninty';
    case 100:
      return 'hundred';
    default:
      return undefined;
  }
}

// capitalize the first letter of a string
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// trim and elipsify
export function trimAndEllipsify(text, noOfChars) {
  return text.length > noOfChars ? `${text.substr(0, noOfChars)}...` : text;
}

// convert urls in text to hyperlinks
export function convertTextLinksToHyperLinks(text) {
  const exp =
    /(\b(https?|http|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const text1 = text.replace(exp, '<a target="_blank" href=$1>$1</a>');
  const exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
}

export function percentage(part, total) {
  return Math.round((parseInt(part, 10) * 100) / parseInt(total, 10));
}

export const monthsDiff = (dateFrom, dateTo) =>
  dateTo.getMonth() -
  dateFrom.getMonth() +
  12 * (dateTo.getFullYear() - dateFrom.getFullYear());

export const sortObjArr = (arr, key, desc = false) =>
  arr.sort((a, b) => {
    var valueA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    var valueB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
    if (valueA < valueB) {
      return desc ? 1 : -1;
    }
    if (valueA > valueB) {
      return desc ? -1 : 1;
    }
    return 0;
  });
