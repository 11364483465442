import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { createCustomer } from 'store/customers/customerActions';
//import { Field } from 'formik';

class CustomerForm extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h3 className="m-0 font-weight-bold text-primary">
                  Add Customer
                </h3>
              </div>
              <div className="card-body">
                <form onSubmit={(e) => this.props.addCustomer(e)}>
                  <div className="form-group row">
                    <label
                      for="inputOrgName"
                      className="col-sm-2 col-form-label required"
                    >
                      Organization Name
                    </label>
                    <div className="col-sm-6">
                      <Field
                        component="input"
                        name="organizationName"
                        className="form-control"
                        id="inputOrgName"
                        placeholder="Organization Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="address"
                      className="col-sm-2 col-form-label required"
                    >
                      Organization Address
                    </label>
                    <div className="col-sm-6">
                      <Field
                        component="input"
                        required
                        name="organizationAddress"
                        className="form-control"
                        id="address"
                        placeholder="Address"
                      />{' '}
                      <br />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="primaryContact"
                      className="col-sm-2 col-form-label required"
                    >
                      Primary Contact
                    </label>
                    <div className="col-md-6">
                      <Field
                        component="input"
                        required
                        name="primaryFirstName"
                        className="form-control"
                        id="inputPrimaryFirstName"
                        placeholder="First Name"
                      />{' '}
                      <br />
                      <Field
                        component="input"
                        required
                        name="primaryLastName"
                        className="form-control"
                        id="inputPrimaryLastName"
                        placeholder="Last Name"
                      />
                      <br />
                      <Field
                        component="input"
                        required
                        name="primaryEmail"
                        className="form-control"
                        id="inputPrimaryEmail"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="secondaryContact"
                      className="col-sm-2 col-form-label"
                    >
                      Secondary Contact
                    </label>
                    <div className="col-sm-6">
                      <Field
                        component="input"
                        name="secondaryFirstName"
                        className="form-control"
                        id="inputSecondaryFirstName"
                        placeholder="First Name"
                      />{' '}
                      <br />
                      <Field
                        component="input"
                        name="secondaryLastName"
                        className="form-control"
                        id="inputSecondaryLastName"
                        placeholder="Last Name"
                      />{' '}
                      <br />
                      <Field
                        component="input"
                        name="secondaryEmail"
                        className="form-control"
                        id="inputSecondaryEmail"
                        placeholder="Email"
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      for="phoneNumbers"
                      className="col-sm-2 col-form-label"
                    >
                      Phone Numbers
                    </label>
                    <div className="col-sm-10">
                      <div className="form-inline">
                        {/* <input type="text" className="col-sm-2 form-control" placeholder="Code" />
                        - */}
                        <Field
                          component="input"
                          name="primaryPhone"
                          id="primaryPhone"
                          className="col-sm-4 form-control"
                          placeholder="Primary Phone"
                        />
                      </div>
                      <br />
                      <div className="form-inline">
                        <Field
                          component="input"
                          name="secondaryPhone"
                          id="secondaryPhone"
                          className="col-sm-4 form-control"
                          placeholder="Secondary Phone"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-2">About Organization</div>
                    <div className="col-sm-10">
                      <label for="organization_industry_type">
                        Industry Type*
                      </label>
                      <div className="form-select">
                        <Field
                          component="select"
                          className="form-control"
                          id="industryType"
                          name="industryType"
                          value="education"
                          required
                        >
                          <option value="" disabled hidden>
                            Select One
                          </option>
                          <option value="education">Education</option>
                          <option value="accelerator">Accelerator</option>
                        </Field>
                      </div>{' '}
                      <br />
                      <label for="organization_topic">
                        Organization-Topic*
                      </label>
                      <div className="form-select">
                        <Field
                          component="select"
                          className="form-control"
                          id="organizationTopic"
                          name="organizationTopic"
                          required
                        >
                          <option value="" disabled hidden>
                            Select One
                          </option>
                          <option value="business">Business</option>
                          <option value="entrepreneurship">
                            Entrepreneurship
                          </option>
                        </Field>
                      </div>{' '}
                      <br />
                      <label for="end_user_level">End-User Level*</label>
                      <div className="form-select">
                        <Field
                          component="select"
                          className="form-control"
                          id="endUserLevel"
                          name="endUserLevel"
                          required
                        >
                          <option value="" disabled hidden>
                            Select One
                          </option>
                          <option value="graduate">Graduate</option>
                          <option value="undergraduate">Undergraduate</option>
                          <option value="high school">High School</option>
                          <option value="professional">Professional</option>
                        </Field>
                      </div>{' '}
                      <br />
                      <label for="is_social_impact_important">
                        Social Impact Important
                      </label>
                      <div className="custom-radio">
                        <div className="form-check">
                          <Field
                            component="input"
                            className="form-check-input"
                            type="radio"
                            name="socialImpactImportant"
                            id="gridRadios1"
                            value="true"
                          />
                          <label className="form-check-label" for="gridRadios1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check">
                          <Field
                            defaultChecked
                            component="input"
                            className="form-check-input"
                            type="radio"
                            name="socialImpactImportant"
                            id="gridRadios2"
                            value="false"
                          />
                          <label className="form-check-label" for="gridRadios2">
                            No
                          </label>
                        </div>
                      </div>{' '}
                      <br />
                      {/*<label for="geo_colocation" >Geo Colocation</label>*/}
                      {/* <div className="col-sm-6">
                        <input type="map" className="form-control" id="map" placeholder="Geo Colocation" /> <br />
                      </div> */}
                      <div className="col-sm-6">
                        <Field
                          component="input"
                          name="taxNumber"
                          className="form-control"
                          id="tax_number"
                          placeholder="Tax Number"
                        />{' '}
                        <br />
                      </div>{' '}
                      <br />
                      <div className="form-inline">
                        <label for="number_of_users">Number of Users:*</label>
                        <div className="col-sm-2">
                          <Field
                            component="select"
                            className="form-control"
                            id="number_of_users"
                            name="noOfUsers"
                            required
                          >
                            <option value="" disabled hidden />
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-100">51-100</option>
                            <option value="101-250">101-250</option>
                            <option value="251-1000">251-500</option>
                            <option value="251-1000">501-1000</option>
                            <option value="1000+">1000+</option>
                          </Field>
                        </div>
                      </div>{' '}
                      <br />
                      <div className="form-inline">
                        <label for="potential_number_of_users">
                          Potential Number of Users:
                        </label>
                        <div className="col-sm-2">
                          <Field
                            component="select"
                            className="form-control"
                            id="potential_number_of_users"
                            name="potentialNoOfUsers"
                          >
                            <option value="" disabled hidden />
                            <option value="1-10">1-10</option>
                            <option value="11-50">11-50</option>
                            <option value="51-100">51-100</option>
                            <option value="101-250">101-250</option>
                            <option value="251-1000">251-500</option>
                            <option value="251-1000">501-1000</option>
                            <option value="1000+">1000+</option>
                          </Field>
                        </div>
                      </div>{' '}
                      <br />
                      <div className="form-inline">
                        <label for="growth_potential">Growth Potential:</label>
                        <div className="col-sm-2">
                          <Field
                            component="select"
                            className="form-control"
                            id="growth_potential"
                            name="growthPotential"
                          >
                            <option value="" disabled hidden />
                            <option value="high">High</option>
                            <option value="medium">Medium</option>
                            <option value="low">Low</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-12">
                      <button type="submit" className="btn btn-primary">
                        Add Customer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addCustomer(e) {
    e.preventDefault();
    dispatch(createCustomer());
  },
  // deleteInvitation(id) {
  //   dispatch(deleteHubInvitation(id));
  // }
});

const mapStateToProps = (state) => {
  // const { hubs } = state;

  // return {
  //   pendingHubInvitations: hubs.hubInvitations
  //     .filter(i => !i.get('is_accepted'))
  //     .toJS(),
  //   acceptedHubInvitations: hubs.hubInvitations
  //     .filter(i => i.get('is_accepted'))
  //     .toJS()
  // };
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'CustomerForm', enableReinitialize: true })(CustomerForm));
