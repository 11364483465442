import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/tables.scss';
import { formatDate } from '../../utils/functions';

class NewUsers extends Component {
  render() {
    return (
      <div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>Id</th>
                <th>First Name </th>
                <th>Last Name </th>
                <th>Email </th>
                <th>Inserted At</th>
              </tr>
            </thead>
            <tbody>
              {this.props.usersLoaded &&
                this.props.users.map((user, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{user.id}</td>
                      <td>{user.first_name}</td>
                      <td>{user.last_name}</td>
                      <td>{user.email_address}</td>
                      <td>{formatDate(user.inserted_at)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users
      .sortBy(user => user.get('inserted_at'))
      .reverse()
      .setSize(5)
      .toJS(),
    usersLoaded: !state.users.isEmpty()
  };
}

export default connect(mapStateToProps)(NewUsers);
