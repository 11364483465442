import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import HubLimitsForm from 'components/HubLimitsForm';

const SecondStep = ({ prevStep, onSubmit, initialValues }) => {
  return (
    <div className="col-lg-12">
      <HubLimitsForm
        handleSubmit={onSubmit}
        secondaryBtnAction={prevStep}
        primaryBtnLabel="Next Step"
        secondaryBtnLabel="Go Back"
        initialValues={initialValues}
      />
    </div>
  );
};

export default SecondStep;
