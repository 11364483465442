import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import QuillField from '../../../shared/quill/quillField';
import loader from 'images/loader.gif';
import '../builderManager.scss';

import {
  addSectionBlueprint,
  editSectionBlueprint,
} from '../../../store/builderManager/builderManagerActions';

export const FileInput = ({
  input,
  type,
  meta: { touched, error, warning },
}) => {
  delete input.value;
  return (
    <div>
      <label htmlFor={input.name}>
        <input {...input} type={type} />
      </label>
    </div>
  );
};

class SectionBlueprintForm extends React.Component {
  renderForm() {
    const {
      initialValues,
      isWorkshopType,
      tempImage,
      isProcessing,
    } = this.props;
    return (
      <div>
        <div className="question__wrap">
          <p className="question required">Type</p>
          <div className="question__option">
            <Field component="select" name="type" required>
              <option value="" disabled>Choose a type</option>
              <option value="section">Section (unclassifed)</option>
              <option value="brief">Brief</option>
              <option value="workshop">Workshop</option>
              <option value="inspiration">Inspiration Byte</option>
            </Field>
          </div>
        </div>
        <h3 className="headthree">English Copy</h3>
        <div className="question__wrap">
          <p className="question required">Title</p>
          <div className="question__option">
            <Field
              type="text"
              component="input"
              name="title"
              placeholder="i.e. Go Fish, Hide and Seek, Coup"
              required
            />
          </div>
        </div>

        {/* <div className="question__wrap">
          <p className="question">Video Link</p>
          <div className="question__option">
            <input type="text" placeholder="www.vimeo.com/gofishrules" />
          </div>
        </div>
        <div className="question__wrap">
          <p className="question">Video Transcript</p>
          <div className="question__option">
            <textarea type="text" placeholder="Each player gets five cards. If you are dealt a four of a kind, or get four of a kind during game play, those cards are removed from your hand, and you get a point."></textarea>
          </div>
        </div> */}
        <div className="question__wrap">
          <p className="question required">Info</p>
          <div className="question__option">
            <QuillField
              name="section_info_delta"
              delta={initialValues ? initialValues.info : null}
            />
          </div>
        </div>
        <h3 className="headthree">Spanish Copy</h3>
        <div className="question__wrap">
          <p className="question required">Title</p>
          <div className="question__option">
            <Field
              type="text"
              component="input"
              name="title_spanish"
              placeholder="i.e. Go Fish, Hide and Seek, Coup"
              required
            />
          </div>
        </div>

        {/* <div className="question__wrap">
          <p className="question">Video Link</p>
          <div className="question__option">
            <input type="text" placeholder="www.vimeo.com/gofishrules" />
          </div>
        </div>
        <div className="question__wrap">
          <p className="question">Video Transcript</p>
          <div className="question__option">
            <textarea type="text" placeholder="Each player gets five cards. If you are dealt a four of a kind, or get four of a kind during game play, those cards are removed from your hand, and you get a point."></textarea>
          </div>
        </div> */}
        <div className="question__wrap">
          <p className="question required">Info</p>
          <div className="question__option">
            <QuillField
              name="section_info_delta_spanish"
              delta={initialValues ? initialValues.info_spanish : null}
            />
          </div>
        </div>
        {isWorkshopType && (
          <section>
            <h3 className="headthree">Rebel Map</h3>
            <div className="question__wrap">
              <p className="question">Image File</p>
              <div className="question__option">
                <Field name="file" type="file" component={FileInput} />
                {tempImage && (
                  <div>
                    <img
                      src={window.URL.createObjectURL(tempImage)}
                      alt="temp"
                    />
                  </div>
                )}
                {!tempImage && initialValues && initialValues.rebel_map_url && (
                  <div>
                    <img src={initialValues.rebel_map_url} alt="temp" />
                  </div>
                )}
              </div>
            </div>

            <div className="question__wrap">
              <p className="question">Interactive file link</p>
              <div className="question__option">
                <Field
                  type="text"
                  component="input"
                  placeholder="url"
                  name="rebel_map_url_1"
                />
              </div>
            </div>

            <div className="question__wrap">
              <p className="question">PNG link</p>
              <div className="question__option">
                <Field
                  type="text"
                  component="input"
                  placeholder="url"
                  name="rebel_map_url_2"
                />
              </div>
            </div>
          </section>
        )}
        {isProcessing ? (
          <button className="btn-save btn-disabled btn-right" disabled>
            <img src={loader} alt="loading" /> saving...
          </button>
        ) : (
          <button type="submit" className="btn-save btn-right">
            save
          </button>
        )}
      </div>
    );
  }

  renderNewSection() {
    const { create } = this.props;

    return (
      <div className="container-fluid admin">
        <div className="[section_edit]">
          <a onClick={() => window.history.back()}>
            <span>&#60; back</span>
          </a>
          <h2 className="headtwo">New Section</h2>
          <form encType="multipart/form-data" onSubmit={(e) => create(e)}>
            {this.renderForm()}
          </form>
        </div>
      </div>
    );
  }

  renderEditSection() {
    const { match, edit } = this.props;
    const { sb_id } = match.params;
    return (
      <div className="container-fluid admin">
        <div className="[section_edit]">
          <a onClick={() => window.history.back()}>
            <span>&#60; back</span>
          </a>
          <h2 className="headtwo">Edit Section</h2>
          <form encType="multipart/form-data" onSubmit={(e) => edit(e, sb_id)}>
            {this.renderForm()}
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { match } = this.props;

    // check if editing existing section blueprint
    if (match.params.sb_id) {
      return this.renderEditSection();
    }
    return this.renderNewSection();
  }
}

const mapDispatchToProps = (dispatch) => ({
  create(event) {
    event.preventDefault();
    dispatch(addSectionBlueprint());
  },
  edit(event, sbId) {
    event.preventDefault();
    dispatch(editSectionBlueprint(sbId));
  },
});

const mapStateToProps = (state, props) => {
  const { form, challengeBuilder, metadata } = state;

  // check if editing
  const sectionBlueprint = challengeBuilder.sectionBlueprints.find(
    (sb) => sb.get('id').toString() === props.match.params.sb_id
  );

  const initialValues = sectionBlueprint
    ? {
        type: sectionBlueprint.get('type'),
        info: sectionBlueprint.get('info'),
        info_spanish: sectionBlueprint.get('info_spanish'),
        title: sectionBlueprint.get('title'),
        title_spanish: sectionBlueprint.get('title_spanish'),
        rebel_map_url_1: sectionBlueprint.get('rebel_map_url_1'),
        rebel_map_url_2: sectionBlueprint.get('rebel_map_url_2'),
        rebel_map_url: sectionBlueprint.get('rebel_map_url'),
      }
    : null;

  // check if type is workshop, so we can display the appropriate fields
  const isWorkshopType =
    form &&
    form.SectionBlueprintForm &&
    form.SectionBlueprintForm.values &&
    form.SectionBlueprintForm.values.type === 'workshop';

  // temp image
  const tempImage =
    form.SectionBlueprintForm &&
    form.SectionBlueprintForm.values &&
    form.SectionBlueprintForm.values.file
      ? form.SectionBlueprintForm.values.file[0]
      : null;
  return {
    initialValues,
    isWorkshopType,
    tempImage,
    isProcessing: metadata.isProcessing,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'SectionBlueprintForm', enableReinitialize: true })(
    SectionBlueprintForm
  )
);
