import React from 'react';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';

import STYLES from './styles';
import './styles.scss';

class Popup extends React.Component {
  render() {
    const { notifications } = this.props;
    return (
      <Notifications
        notifications={notifications}
        style={STYLES}
      />
    );
  }
}

export default connect(state => ({ notifications: state.popups }))(Popup);
