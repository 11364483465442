export const SET_REDIRECT = 'SET_REDIRECT';
export const RESET_REDIRECT = 'RESET_REDIRECT';

export function doRedirect(url) {
  return (dispatch) => {
    dispatch({type: SET_REDIRECT, url})
  }
}

export const OPEN_COVER_IMAGE_UPLOAD_MODAL = 'OPEN_COVER_IMAGE_UPLOAD_MODAL';
export const CLOSE_COVER_IMAGE_UPLOAD_MODAL = 'CLOSE_COVER_IMAGE_UPLOAD_MODAL';

export const SET_LOADED = 'metadata/SET_LOADED';

// is processing
export const SET_PROCESSING = 'metadata/SET_PROCESSING';
export const RESET_PROCESSING = 'metadata/RESET_PROCESSING';
