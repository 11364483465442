import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Provider, connect } from 'react-redux';
import { initializeApp } from './store/actions';
import RouterComponent from './routerComponent';
import TopNav from './views/topNav/TopNav';
import SideNav from './views/sideNav/SideNav';
import Popup from './shared/popup/Popup';
import Loading from './views/utils/loading/Loading';
import DoRedirect from './views/utils/doRedirect';
import './css/sb-admin-2.min.css';
import Cookie from 'js-cookie';



// import './app.scss';
const jwt = Cookie.get('admin_token');

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      admin_token: null,
    };
  }

  componentDidMount() {
    this.setState({ admin_token: jwt });
    jwt && this.props.initializeApp();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.jwt !== this.props.user.jwt) {
      this.setState({ admin_token: this.props.user.jwt });
    }
  }

  render() {
    const { store, isModalOpen, wrapper } = this.props;
    const { admin_token } = this.state;
    return (
      <Provider store={store}>
        <Router>
          <LastLocationProvider>
            <div className={`${admin_token ? 'wrapper' : ''}`}>
              {/* <Loading /> */}
              <div id="wrapper">
                {admin_token && <SideNav />}
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                    {admin_token && <TopNav />}
                    <RouterComponent isModalOpen={isModalOpen} />
                  </div>
                </div>
                <a className="scroll-to-top rounded" onClick={() => window.scrollTo(0, 0)}>
                  <i className="fas fa-angle-up"></i>
                </a>
              </div>
              <Popup />
              <DoRedirect />
            </div>
          </LastLocationProvider>
        </Router>
      </Provider>
    );
  }
}

function mapStateToProps(state) {
  const { sideNav, user } = state;

  // adjust page css depending on whether the side nav is open or not
  const wrapper = sideNav.get('menuOpen') ? 'wrapper' : 'wrapper__sideHidden';

  // check to see if modal route
  const isModalOpen =
    window.location.pathname === '/builder-manager/challenge-buckets/add' ||
    window.location.pathname ===
    '/builder-manager/challenge-buckets/:cbb_id/edit';

  return {
    isModalOpen,
    wrapper,
    user: user.toJS(),
  };
}

const mapDispatchToProps = {
  initializeApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
