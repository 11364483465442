import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import 'css/tables.scss';
import { formatDate } from 'utils/functions';

const $ = window.$;

class UserList extends React.Component {
  componentDidMount() {
    $(window.document).ready(function () {
      $('#userList').DataTable();
    });
  }

  componentDidUpdate() {
    $(window.document).ready(function () {
      $('#userList').DataTable();
    });
  }

  render() {
    const { users } = this.props;
    const tableData = users.map((user) => {
      return (
        <tr key={`user-${user.id}`}>
          <td>{user.id}</td>
          <td>{user.first_name}</td>
          <td>{user.last_name}</td>
          <td>{user.email_address}</td>
          <td>{user.is_rb_staff ? 'yes' : 'no'}</td>
          <td>{formatDate(user.inserted_at)}</td>
          <td>{formatDate(user.updated_at)}</td>
        </tr>
      );
    })
    return (
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Accounts Table</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" id="userList" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>First Name </th>
                  <th>Last Name </th>
                  <th>Email </th>
                  <th>Staff</th>
                  <th>Inserted At</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Id</th>
                  <th>First Name </th>
                  <th>Last Name </th>
                  <th>Email </th>
                  <th>Staff</th>
                  <th>Inserted At</th>
                  <th>Updated At</th>
                </tr>
              </tfoot>
              <tbody>
                {tableData}
              </tbody>
            </table>
          </div>
        </div>
      </div>);
  }
}

// const UserList = ({ users }) => (
//   // <div>
//   //   <h3>Accounts</h3>
//   //   <div>
//   //     <div>
//   //       <form>
//   //         <Field
//   //           type="text"
//   //           component="input"
//   //           placeholder="Search"
//   //           name="search"
//   //         />
//   //         <br />
//   //       </form>
//   //     </div>
//   //     <table className="table">
//   //       <thead>
//   //         <tr>
//   //           <th />
//   //           <th>Id</th>
//   //           <th>First Name </th>
//   //           <th>Last Name </th>
//   //           <th>Email </th>
//   //           <th>Staff</th>
//   //           <th>Inserted At</th>
//   //         </tr>
//   //       </thead>
//   //       <tbody>
//   //         {users.map((user, index) => {
//   //           return (
//   //             <tr>
//   //               <td>{index + 1}</td>
//   //               <td>{user.id}</td>
//   //               <td>{user.first_name}</td>
//   //               <td>{user.last_name}</td>
//   //               <td>{user.email_address}</td>
//   //               <td>{user.is_rb_staff ? 'yes' : 'no'}</td>
//   //               <td>{formatDate(user.inserted_at)}</td>
//   //             </tr>
//   //           );
//   //         })}
//   //       </tbody>
//   //     </table>
//   //   </div>
//   // </div>
//   $(document).ready(function() {
//     $('#dataTable').DataTable();
//   });


// );

function mapStateToProps(state) {
  const { users, form } = state;
  const serachString =
    form.UserListForm && form.UserListForm.values
      ? form.UserListForm.values.search.toLowerCase()
      : null;
  const filterUserList = serachString
    ? users.filter(
      (user) =>
        user.get('first_name').toLowerCase().includes(serachString) ||
        user.get('last_name').toLowerCase().includes(serachString) ||
        user.get('email_address').toLowerCase().includes(serachString)
    )
    : users;
  return {
    users: filterUserList.sortBy((user) => user.get('inserted_at')).toJS(),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'UserListForm',
    enableReinitialize: true,
  })(UserList)
);
