import { request } from 'utils/functions';

export const SET_PROJECTS = 'SET_PROJECTS';

export function setProjects(data) {
  return {
    type: SET_PROJECTS,
    data,
  };
}

export function fetchProjects() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/venture/projects';
    return request(url, jwt).then((response) =>
      dispatch(setProjects(response.data))
    );
  };
}
