import { combineReducers } from 'redux';
import { SET_REDIRECT, RESET_REDIRECT, OPEN_COVER_IMAGE_UPLOAD_MODAL, CLOSE_COVER_IMAGE_UPLOAD_MODAL, SET_LOADED, SET_NO_PERMISSION, SET_PROCESSING, RESET_PROCESSING } from './metadataActions';

// REDUCER
function redirectTo(state = false, action) {
  switch (action.type) {
    case SET_REDIRECT:
      return action.url;
    case RESET_REDIRECT:
      return false;
    default:
      return state;
  }
}

function isUploadingCoverImage(state = false, action) {
  switch (action.type) {
    case OPEN_COVER_IMAGE_UPLOAD_MODAL:
      return true;
    case CLOSE_COVER_IMAGE_UPLOAD_MODAL:
      return false;
    default:
      return state;
  }
}

function isLoaded(state = false, action) {
  if (action.type === SET_LOADED) {
    return true;
  }
  return state;
}

function isProcessing(state = false, action) {
  switch (action.type) {
    case SET_PROCESSING:
      return true;
    case RESET_PROCESSING:
      return false;
    default:
      return state;
  }
}

export default combineReducers({
  redirectTo,
  isUploadingCoverImage,
  isLoaded,
  isProcessing,
});
