import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { useSelector, useDispatch } from 'react-redux';
import { popup } from 'shared/popup/popupActions';
import './firstStep.css';

const SelectCustomer = ({ customers, value, onChange }) => {
  return (
    <SelectSearch
      options={customers.map((customer) => ({
        value: customer.id,
        name: customer.organization_name,
      }))}
      name="customer_id"
      search
      value={value}
      onChange={onChange}
      filterOptions={fuzzySearch}
      emptyMessage={() => (
        <Link to="/customers/add" style={{ textAlign: 'center' }}>
          Create Customer
        </Link>
      )}
      placeholder="Select Customer*"
    />
  );
};

const FirstStep = ({ onSubmit, initialValues }) => {
  const customers = useSelector((state) => state.customers.customers);
  const [customerId, setCustomerId] = useState(initialValues.customer_id);
  const dispatch = useDispatch();
  return (
    <div className="col-lg-4">
      <Formik
        onSubmit={(values) => {
          if (!customerId)
            return dispatch(
              popup('Please select a Customer', 'error', ' ', 'tc', 5)
            );
          values = {
            ...values,
            customer_id: customerId,
          };
          onSubmit(values);
        }}
        initialValues={initialValues}
        enableReinitialize
      >
        <Form>
          <div className="form-group">
            <Field
              name="name"
              className="form-control form-control-user"
              component="input"
              placeHolder="Name*"
              required
            />
          </div>
          <div className="form-group">
            <Field
              name="email_address"
              className="form-control form-control-user"
              component="input"
              placeHolder="Email Address*"
              required
            />
          </div>
          <div className="form-group">
            <Field
              className="form-control form-control-user"
              name="hub_name"
              component="input"
              placeHolder="Name of Hub*"
              required
            />
          </div>
          <div className="form-group">
            <Field
              name="customer_id"
              component={SelectCustomer}
              value={customerId}
              customers={customers.toJS()}
              onChange={(value) => setCustomerId(value)}
              required
            />
          </div>
          <input
            className="btn btn-primary btn-user"
            type="submit"
            value="Next step"
            fullWidth
            variant="contained"
            color="primary"
          />
        </Form>
      </Formik>
    </div>
  );
};

export default FirstStep;
