import { reset } from 'redux-form';

import { validateEmail, request } from 'utils/functions';
import { removePopups, popup } from 'shared/popup/popupActions';

export const SET_CUSTOMERS = 'customers/SET_CUSTOMERS';
export const SET_CUSTOMER = 'customers/SET_CUSTOMER';

export function fetchCustomers() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v3/admin/sales/customers';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_CUSTOMERS, data: response.data })
    );
  };
}

export function updateCustomer() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v3/admin/sales/customers';
    return request(url, jwt).then((response) =>
      dispatch({ type: SET_CUSTOMERS, data: response.data })
    );
  };
}


export function createCustomer() {
  return (dispatch, getState) => {
    const { user, form } = getState();
    const jwt = user.get('jwt');
    
    //validation
    if (
      !form.CustomerForm ||
      !form.CustomerForm.values ||
      !form.CustomerForm.values.organizationName||
      !form.CustomerForm.values.organizationAddress||
      !form.CustomerForm.values.primaryFirstName||
      !form.CustomerForm.values.primaryLastName||
      !form.CustomerForm.values.primaryEmail
    ) {

     
      dispatch(
        popup(
          'Please fill in all the fields.',
          'error',
          ' ',
          'tc',
          0,
          true,
          null,
          'validation_failed'
        )
      );
      dispatch({ type: SET_CUSTOMER });
      return;
    }
    // create payload
    const url =
      window.env.REACT_APP_API_ENDPOINT +
      '/api/v3/admin/sales/customers';
    const payload = {
      customer: {
        organization_name: form.CustomerForm.values.organizationName,
        organization_address: form.CustomerForm.values.organizationAddress,
        primary_contact_first_name: form.CustomerForm.values.primaryFirstName,
        primary_contact_last_name: form.CustomerForm.values.primaryLastName,
        primary_contact_email_address: form.CustomerForm.values.primaryEmail,
        secondary_contact_first_name: form.CustomerForm.values.secondaryFirstName,
        secondary_contact_last_name: form.CustomerForm.values.secondaryLastName,
        secondary_contact_email_address: form.CustomerForm.values.secondaryEmail,
        primary_phone_number: form.CustomerForm.values.primaryPhone,
        secondary_phone_number: form.CustomerForm.values.secondaryPhone,
        organization_industry_type: form.CustomerForm.values.industryType,
        organization_topic: form.CustomerForm.values.organizationTopic,
        end_user_level: form.CustomerForm.values.endUserLevel,
        is_social_impact_important: !!form.CustomerForm.values.socialImpactImportant === "true",
        tax_number: form.CustomerForm.values.taxNumber,
        number_of_users: form.CustomerForm.values.noOfUsers,
        potential_number_of_users: form.CustomerForm.values.potentialNoOfUsers,
        growth_potential: form.CustomerForm.values.growthPotential,
      },
    };

    // make request
    request(url, jwt, 'POST', payload)
      .then((response) => {
        dispatch({ type: SET_CUSTOMER, data: response.data });
        dispatch(reset('CustomerForm'));
      })
      .catch((err) => console.log(err));
  }
}
