import { List, fromJS } from 'immutable';
import { combineReducers } from 'redux';

import * as BP from './builderManagerActions';

const initChallengebuckets = List();
export function challengeBucketBlueprints(
  state = initChallengebuckets,
  action
) {
  switch (action.type) {
    case BP.SET_CHALLENGE_BUCKET_BLUEPRINTS:
      return List().concat(fromJS(action.data));
    case BP.SET_CHALLENGE_BUCKET_BLUEPRINT:
      return state.push(fromJS(action.data));
    case BP.UPDATE_CHALLENGE_BUCKET_BLUEPRINTS:
      return state.update(
        state.findIndex((cbb) => {
          return cbb.get('id') === action.data.id;
        }),
        () => {
          return fromJS(action.data);
        }
      );
    default:
      return state;
  }
}

const initChallengeTracks = List();
export function challengeTrackBlueprints(state = initChallengeTracks, action) {
  switch (action.type) {
    case BP.SET_CHALLENGE_TRACK_BLUEPRINTS:
      return List().concat(fromJS(action.data));
    case BP.SET_CHALLENGE_TRACK_BLUEPRINT:
      return state.push(fromJS(action.data));
    case BP.UPDATE_CHALLENGE_TRACK_BLUEPRINTS:
      return state.update(
        state.findIndex((cbb) => {
          return cbb.get('id') === action.data.id;
        }),
        () => {
          return fromJS(action.data);
        }
      );
    default:
      return state;
  }
}

const initChallengeBlueprints = List();
export function challengeBlueprints(state = initChallengeBlueprints, action) {
  switch (action.type) {
    case BP.SET_CHALLENGE_BLUEPRINTS:
      return List().concat(fromJS(action.data));
    case BP.SET_CHALLENGE_BLUEPRINT:
      return state
        .filterNot((cb) => cb.get('id') === action.data.id)
        .push(fromJS(action.data));
    case BP.UPDATE_CHALLENGE_BLUEPRINTS:
      return state.update(
        state.findIndex((cb) => {
          return cb.get('id') === action.data.id;
        }),
        () => {
          return fromJS(action.data);
        }
      );
    default:
      return state;
  }
}

const initTopicBlueprints = List();
export function topicBlueprints(state = initTopicBlueprints, action) {
  switch (action.type) {
    case BP.SET_TOPIC_BLUEPRINTS:
      return List().concat(fromJS(action.data));
    case BP.SET_TOPIC_BLUEPRINT:
      return state
        .filterNot((tb) => tb.get('id') === action.data.id)
        .push(fromJS(action.data));
    case BP.UPDATE_TOPIC_BLUEPRINTS:
      return state.update(
        state.findIndex((tb) => {
          return tb.get('id') === action.data.id;
        }),
        () => fromJS(action.data)
      );
    default:
      return state;
  }
}

const initAnswerBlueprints = List();
export function answerBlueprints(state = initAnswerBlueprints, action) {
  switch (action.type) {
    case BP.SET_ANSWER_BLUEPRINTS:
      return List().concat(fromJS(action.data));
    case BP.SET_ANSWER_BLUEPRINT:
      return state
        .filterNot((ab) => ab.get('id') === action.data.id)
        .push(fromJS(action.data));
    case BP.UPDATE_ANSWER_BLUEPRINT:
      return state.update(
        state.findIndex((ab) => {
          return ab.get('id') === action.data.id;
        }),
        () => fromJS(action.data)
      );
    default:
      return state;
  }
}

const initWorkshoplueprints = List();
export function workshopBlueprints(state = initWorkshoplueprints, action) {
  switch (action.type) {
    case BP.SET_WORKSHOP_BLUEPRINTS:
      return state.concat(fromJS(action.data));
    case BP.SET_WORKSHOP_BLUEPRINT:
      return state
        .filterNot((wb) => wb.get('id') === action.data.id)
        .push(fromJS(action.data));
    case BP.UPDATE_WORKSHOP_BLUEPRINT:
      return state.update(
        state.findIndex((wb) => {
          return wb.get('id') === action.data.id;
        }),
        () => fromJS(action.data)
      );
    default:
      return state;
  }
}

const initSectionBlueprints = List();
export function sectionBlueprints(state = initSectionBlueprints, action) {
  switch (action.type) {
    case BP.SET_SECTION_BLUEPRINTS:
      return state.concat(fromJS(action.data));
    case BP.SET_SECTION_BLUEPRINT:
      return state
        .filterNot((sb) => sb.get('id') === action.data.id)
        .push(fromJS(action.data));
    case BP.DELETE_SECTION_BLUEPRINT:
      return state.filterNot((sb) => sb.get('id') === action.id);
    default:
      return state;
  }
}

const initTopicSections = List();
export function topicSections(state = initTopicSections, action) {
  switch (action.type) {
    case BP.SET_TOPIC_SECTIONS:
      return state.concat(fromJS(action.data));
    case BP.SET_TOPIC_SECTION:
      return state
        .filterNot((sb) => sb.get('id') === action.data.id)
        .push(fromJS(action.data));
    case BP.DELETE_TOPIC_SECTION:
      return state.filterNot((sb) => sb.get('id') === action.id);
    default:
      return state;
  }
}

export default combineReducers({
  answerBlueprints,
  challengeBlueprints,
  challengeBucketBlueprints,
  challengeTrackBlueprints,
  topicBlueprints,
  workshopBlueprints,
  sectionBlueprints,
  topicSections,
});
