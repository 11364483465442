import { Map, fromJS } from 'immutable';

import {
  SET_USER,
  SET_USER_ID,
  SET_JWT,
  SET_EMAIL,
  REMOVE_JWT,
} from './userActions';

// REDUCER
const initUser = Map(
  fromJS({
    email_address: null,
    first_name: null,
    id: null,
    last_name: null,
    jwt: null,
    location_map: null,
    purpose_id: null,
    avatar_url: null,
    is_rb_staff: null,
  })
);
function user(state = initUser, action) {
  switch (action.type) {
    case SET_USER:
      return state.merge(action.data);
    case SET_USER_ID:
      return state.set('id', action.userId);
    case SET_JWT:
      return state.set('jwt', action.jwt);
    case SET_EMAIL:
      return state.set('email_address', action.emailAddress);
    case REMOVE_JWT:
      return state.set('jwt', null);
    default:
      return state;
  }
}

export default user;
