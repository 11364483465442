import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { reduxForm, Field } from 'redux-form';
import { CLOSE_COVER_IMAGE_UPLOAD_MODAL } from '../../../store/metadata/metadataActions';
// import {
//   closeLogoUpload,
//   validateLogo,
// } from '../actions';

// Temporary style for modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
  },
};

export const FileInput = ({ input, type, meta: { touched, error, warning } }) => {
  delete input.value;
  return (
    <div>
      <label htmlFor={input.name}>
        <input {...input} type={type} />
      </label>
    </div>
  );
};

const CoverImageUpload = ({ isUploadingCoverImage, close, save, tempImage }) => (
  <div>
    <Modal
      isOpen={isUploadingCoverImage}
      style={customStyles}
      contentLabel="Cover Image Upload Modal"
    >
      <button onClick={() => close()} className="btn-x">x</button>
      <p>Upload a cover image</p>
      <form encType="multipart/form-data">
        <Field name="file" type="file" component={FileInput} />
        {tempImage &&
          <div>
            <img src={window.URL.createObjectURL(tempImage)} alt="temp"  className="admin__modal__images"/>
          </div>
        }
        <button className="btn-right" onClick={e => save(e)}>Ok</button>
      </form>
    </Modal>
  </div>
);

const mapDispatchToProps = dispatch => ({
  close() {
    dispatch({ type: CLOSE_COVER_IMAGE_UPLOAD_MODAL });
  },
  save(event) {
    event.preventDefault();
    dispatch({ type: CLOSE_COVER_IMAGE_UPLOAD_MODAL });
  },
});

function mapStateToProps(state) {
  const { form, metadata } = state;
  const isUploadingCoverImage = metadata.isUploadingCoverImage;
  const tempImage = form.CoverImageUploadForm && form.CoverImageUploadForm.values ? form.CoverImageUploadForm.values.file[0] : null;
  return {
    isUploadingCoverImage,
    tempImage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'CoverImageUploadForm' })(CoverImageUpload));