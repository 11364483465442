import { setStoreLogin, fetchUser } from './user/userActions';
import { fetchChallengeBuilderData } from './builderManager/builderManagerActions';
import { fetchHubInvitations, fetchHubLimits, fetchHubs, fetchSubscriptions } from './hubs/hubActions';
import { fetchCustomers } from './customers/customerActions';
import { fetchUsers } from './accounts/accountActions';
import { fetchProjects } from './projects/projectActions';
import { SET_LOADED, doRedirect } from './metadata/metadataActions';
import Cookie from 'js-cookie';

// data load
export function loadAppData() {
  return (dispatch) => {
    dispatch(fetchUser());
    dispatch(fetchHubInvitations());
    dispatch(fetchChallengeBuilderData());
    dispatch(fetchUsers());
    dispatch(fetchProjects());
    dispatch(fetchHubs());
    dispatch(fetchCustomers());
    dispatch(fetchSubscriptions());
    dispatch(fetchHubLimits());
  };
}

// initial app load
export function initializeApp() {
  return (dispatch) => {
    const jwt = Cookie.get('admin_token');
    if (jwt) {
      dispatch(setStoreLogin(jwt));
      dispatch(loadAppData());
      dispatch({ type: SET_LOADED });
    } 
  };
}
