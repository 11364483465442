import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import iconSearch from 'images/icon_search.png';

import { Field, reduxForm } from 'redux-form';
import Modal from 'react-modal';

import { modalCss } from 'views/utils/modalCss';
import {
  removeTopicSection,
  addTopicSection,
} from 'store/builderManager/builderManagerActions';

function sectionTypeLabel(sectionType) {
  switch (sectionType) {
    case 'workshop':
      return 'Workshop';
    case 'brief':
      return 'Brief';
    case 'inspiration':
      return 'Inspir. byte';
    default:
      return 'Section';
  }
}

class ManageSections extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: '' };
  }

  render() {
    const {
      sectionBlueprintsWithSelectedTopics,
      add,
      remove,
      topicBlueprintId,
    } = this.props;

    const filteredSectionBlueprintsWithSelectedTopics = sectionBlueprintsWithSelectedTopics.filter(
      (sb) => sb.title.toLowerCase().includes(this.state.searchValue)
    );
    return (
      <Modal
        isOpen={true}
        contentLabel="Activity Media Modal"
        onRequestClose={() => window.history.back()}
        shouldCloseOnOverlayClick={true}
        style={modalCss}
      >
        <button onClick={() => window.history.back()} className="btn-x">
          x
        </button>
        <div className="manageSections admin">
          {/* <div className="bread-crumbs">
            <a>Builder Manager </a> > <a>Ideation Track</a> > <a>Problem Builder</a> > <a>Need Topic</a> > Manage Sections
        </div> */}
          <h3>Need - Manage Sections</h3>
          <p>Add or remove sections from this topic</p>
          <form
            className="section__searchbox"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              className="section__input"
              onChange={(input) =>
                this.setState({ searchValue: input.target.value })
              }
            />
            <button className="section__searchbox__btn">
              <img src={iconSearch} />
            </button>
          </form>
          <div className="manageSections__results">
            <p className="manageSections__results__title">Sections (#)</p>
            <ul className="manageSections__results__table">
              {filteredSectionBlueprintsWithSelectedTopics.map((sb) => (
                <li className="manageSections__results__item">
                  <span>
                    {sectionTypeLabel(sb.type)} - {sb.title}
                  </span>
                  <div>
                    {sb.topicSection ? (
                      <a
                        className="manageSections__results__remove"
                        onClick={() => remove(sb.topicSection.id)}
                      >
                        remove
                      </a>
                    ) : (
                      <a
                        className="manageSections__results__add"
                        onClick={() => add(topicBlueprintId, sb.id)}
                      >
                        add
                      </a>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  remove(topicSectionId) {
    dispatch(removeTopicSection(topicSectionId));
  },
  add(topicBlueprintId, sectionBlueprintId) {
    dispatch(addTopicSection(topicBlueprintId, sectionBlueprintId));
  },
});

function mapStateToProps(state, props) {
  const { topicSections, sectionBlueprints } = state.challengeBuilder;

  const topicBlueprintId = parseInt(props.match.params.tb_id, 10);

  const currentTopicSections = topicSections.filter(
    (ts) => ts.get('topic_blueprint_id') === topicBlueprintId
  );

  const sectionBlueprintsWithSelectedTopics = sectionBlueprints.map((sb) => {
    const topicSection = currentTopicSections.find(
      (ts) => ts.get('section_blueprint_id') === sb.get('id')
    );

    return sb.set('topicSection', topicSection);
  });

  return {
    topicBlueprintId,
    sectionBlueprintsWithSelectedTopics: sectionBlueprintsWithSelectedTopics
      ? sectionBlueprintsWithSelectedTopics.toJS()
      : [],
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({ form: 'createEvent', enableReinitialize: true })(ManageSections)
  )
);
