import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { monthsDiff } from 'utils/functions';
import loader from 'images/loader.gif';
import * as Yup from 'yup';
import clsx from 'clsx';

const SubscriptionSchema = Yup.object().shape({
  type: Yup.mixed()
    .oneOf(
      ['trial', 'basic', 'advanced', 'enterprise'],
      'Please select a valid type'
    )
    .required('Required'),
  currency: Yup.mixed()
    .oneOf(['USD', 'EUR'], 'Please select a valid currency')
    .required('Required'),
  price: Yup.number().min(0, 'Please enter valid price').required('Required'),
  start_date: Yup.date().required('Required'),
  end_date: Yup.date()
    .when('start_date', (start_date) => {
      if (start_date) {
        return Yup.date().min(
          start_date,
          'Please enter an end date later than the start date'
        );
      }
    })
    .required('Required'),
});

const ErrorMsgComponent = ({ name }) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <div id={name} class="invalid-feedback">
        {msg}
      </div>
    )}
  </ErrorMessage>
);
class Confirm extends Component {
  render() {
    return (
      <div className="col-lg-10">
        <Formik
          validationSchema={SubscriptionSchema}
          onSubmit={(values) => {
            const startDate = new Date(values.start_date);
            const endDate = new Date(values.end_date);
            values = {
              ...values,
              start_date: startDate.toISOString(),
              end_date: endDate.toISOString(),
              subscription_period: monthsDiff(startDate, endDate) || 1,
            };
            this.props.onSubmit(values);
          }}
          initialValues={{
            type: 'trial',
            currency: 'EUR',
            ...this.props.initialValues,
          }}
          enableReinitialize
        >
          {({ errors, touched }) => (
            <Form>
              <h5>Add Subscription</h5>

              <div className="form-group row">
                <label
                  for="sub_type"
                  className="col-sm-2 col-form-label required"
                >
                  Subscription Type
                </label>
                <div className="col-sm-6">
                  <Field
                    className={clsx(
                      'form-control',
                      errors.type && touched.type && 'is-invalid'
                    )}
                    name="type"
                    id="type"
                    as="select"
                    required
                  >
                    <option value="trial">Trial</option>
                    <option value="basic">Basic</option>
                    <option value="advanced">Advanced</option>
                    <option value="enterprise">Enterprise</option>
                  </Field>
                  <ErrorMsgComponent name="type" />
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="currency"
                  className="col-sm-2 col-form-label required"
                >
                  Currency
                </label>
                <div className="col-sm-6">
                  <Field
                    as="select"
                    className={clsx(
                      'form-control',
                      errors.currency && touched.currency && 'is-invalid'
                    )}
                    name="currency"
                    id="currency"
                    required
                  >
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                  </Field>
                  <ErrorMsgComponent name="currency" />
                </div>
              </div>

              <div className="form-group row">
                <label for="price" className="col-sm-2 col-form-label required">
                  Price
                </label>
                <div className="col-sm-6">
                  <Field
                    name="price"
                    id="price"
                    type="number"
                    min="0"
                    className={clsx(
                      'form-control',
                      errors.price && touched.price && 'is-invalid'
                    )}
                    placeholder="Price"
                    required
                  />
                  <ErrorMsgComponent name="price" />
                </div>
              </div>
              <div className="form-group row">
                <label
                  for="start_date"
                  className="col-sm-2 col-form-label required"
                >
                  Starting Date
                </label>
                <div className="col-sm-6">
                  <Field
                    name="start_date"
                    id="start_date"
                    type="date"
                    className={clsx(
                      'form-control',
                      errors.start_date && touched.start_date && 'is-invalid'
                    )}
                    required
                  />
                  <ErrorMsgComponent name="start_date" />
                </div>
              </div>
              <div className="form-group row">
                <label
                  for="end_date"
                  className="col-sm-2 col-form-label required"
                >
                  End Date
                </label>
                <div className="col-sm-6">
                  <Field
                    name="end_date"
                    type="date"
                    id="end_date"
                    className={clsx(
                      'form-control',
                      errors.end_date && touched.end_date && 'is-invalid'
                    )}
                    required
                  />
                  <ErrorMsgComponent name="end_date" />
                </div>
              </div>
              <div className="form-group row">
                {!this.props.isUpdateSubscription ? (
                  <div className="col-lg-12">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ marginRight: '1rem' }}
                      onClick={this.props.prevStep}
                    >
                      Go Back
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={this.props.isSubmitting}
                    >
                      {this.props.isSubmitting ? (
                        <img src={loader} />
                      ) : (
                        'Submit invitation'
                      )}
                    </button>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.props.isSubmitting}
                  >
                    {this.props.isSubmitting ? (
                      <img src={loader} />
                    ) : (
                      this.props.label || 'Submit'
                    )}
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
export default Confirm;
