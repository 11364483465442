import React from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import QuillField from '../../../shared/quill/quillField';
import Loader from 'images/loader.gif';
import CoverImageUpload from './coverImageUpload';

import {
  addChallengeBlueprint,
  updateChallengeBlueprint,
} from '../../../store/builderManager/builderManagerActions';
import {
  OPEN_COVER_IMAGE_UPLOAD_MODAL,
  RESET_REDIRECT,
} from '../../../store/metadata/metadataActions';

class BuilderBlueprintForm extends React.Component {
  renderForm() {
    const {
      openCoverImageUploadForm,
      tempImage,
      currentImage,
      initialValues,
      trackBlueprints,
      isProcessing,
      bucketBlueprints,
    } = this.props;
    return (
      <div className="admin-builder">
        <section className="[Basics]">
          <h3 className="headthree">Basics</h3>

          <div className="question__wrap">
            <p className="question required">Track Sort Order</p>
            <div className="question__option">
              <Field
                type="number"
                component="input"
                name="track_sort_order"
                required
              />
            </div>
          </div>

          <div className="question__wrap">
            <p className="question required">Bucket Sort Order</p>
            <div className="question__option">
              <Field
                type="number"
                component="input"
                name="bucket_sort_order"
                required
              />
            </div>
          </div>

          <div className="question__wrap">
            <p className="question">Track</p>
            <div className="question__option">
              <Field
                component="select"
                name="challenge_track_blueprint_id"
                disabled={true}
              >
                {trackBlueprints.map((tb) => (
                  <option value={tb.id}>{tb.name}</option>
                ))}
              </Field>
            </div>
          </div>

          <div className="question__wrap">
            <p className="question required">Level</p>
            <div className="question__option">
              <Field
                type="number"
                component="input"
                placeholder="level"
                name="level"
                required
              />
            </div>
          </div>
          <div className="question__wrap">
            <p className="question">Difficulty Level</p>
            <div className="question__option">
              <Field
                type="text"
                component="input"
                placeholder="time"
                name="approximate_time"
              />
            </div>
          </div>
          {/* <div className="question__wrap">
            <p className="question">Tags</p>
            <div className="question__option">
              <input type="text" placeholder="tags separated by commas" />
            </div>
          </div> */}
          <div className="question__wrap">
            <p className="question required">Challenge Bucket</p>
            <div className="question__option">
              <Field
                component="select"
                name="challenge_bucket_blueprint_id"
                required
              >
                <option value="" disabled>
                  Select Challenge Bucket
                </option>
                {bucketBlueprints.map((bb) => (
                  <option value={bb.id}>{bb.title}</option>
                ))}
              </Field>
            </div>
          </div>
          <div className="question__wrap">
            <p className="question required">Cover Image</p>
            <div className="question__option">
              {(tempImage || currentImage) && (
                <div>
                  {tempImage ? (
                    <img
                      src={window.URL.createObjectURL(tempImage)}
                      alt="temp"
                    />
                  ) : (
                    <img src={currentImage} alt="temp" />
                  )}
                </div>
              )}
              <a
                tabIndex="0"
                role="button"
                onClick={() => openCoverImageUploadForm()}
              >
                upload new image
              </a>
              <CoverImageUpload />
            </div>
          </div>
          <div className="question__wrap">
            <p className="question">Is Active</p>
            <div className="question__option">
              <Field type="checkbox" component="input" name="is_active" />
            </div>
          </div>
        </section>

        <section className="[Introduction]">
          <h3 className="headthree">English Version</h3>

          <div className="question__wrap">
            <p className="question required">Builder Title</p>
            <div className="radio question__option">
              <Field
                type="text"
                component="input"
                placeholder="builder title"
                name="title"
                required
              />
            </div>
          </div>

          <div className="question__wrap">
            <p className="question required">Intro Title</p>
            <div className="question__option">
              <Field
                type="text"
                component="input"
                placeholder="intro title"
                name="intro_title"
                required
              />
            </div>
          </div>
          <div className="question__wrap">
            <p className="question required">About Title</p>
            <div className="question__option">
              <Field
                type="text"
                component="input"
                placeholder="about title"
                name="about_title"
                required
              />
            </div>
          </div>
          <div className="question__wrap">
            <p className="question required">Intro Information</p>
            <div className="question__option">
              <QuillField
                name="about_delta"
                className="question__quill"
                delta={initialValues ? initialValues.about_delta : null}
              />
            </div>
          </div>
          {/* <div className="question__wrap">
            <p className="question">Rebel Skills</p>
            <div className="question__option" required>
              <Field
                type="text"
                component="input"
                placeholder="one"
                name="learning_outcome1"
              />
              <Field
                type="text"
                component="input"
                placeholder="two"
                name="learning_outcome2"
              />
              <Field
                type="text"
                component="input"
                placeholder="three"
                name="learning_outcome3"
              />
              <Field
                type="text"
                component="input"
                placeholder="four"
                name="learning_outcome4"
              />
              <Field
                type="text"
                component="input"
                placeholder="five"
                name="learning_outcome5"
              />
            </div> */}
          {/* </div> */}
        </section>

        <section className="[Introduction]">
          <h3 className="headthree">Spanish Version</h3>

          <div className="question__wrap">
            <p className="question required">Builder Title</p>
            <div className="radio question__option">
              <Field
                type="text"
                component="input"
                placeholder="builder title"
                name="title_spanish"
                required
              />
            </div>
          </div>

          <div className="question__wrap">
            <p className="question required">Intro Title</p>
            <div className="question__option">
              <Field
                type="text"
                component="input"
                placeholder="intro title"
                name="intro_title_spanish"
                required
              />
            </div>
          </div>
          <div className="question__wrap">
            <p className="question required">About Title</p>
            <div className="question__option">
              <Field
                type="text"
                component="input"
                placeholder="about title"
                name="about_title_spanish"
                required
              />
            </div>
          </div>
          <div className="question__wrap">
            <p className="question required">Intro Information</p>
            <div className="question__option">
              <QuillField
                name="about_delta_spanish"
                className="question__quill"
                delta={initialValues ? initialValues.about_delta_spanish : null}
              />
            </div>
          </div>
        </section>

        {/* <section className="[Introduction]">
          <h3 className="headthree">Conclusion</h3>
          <div className="question__wrap">
            <p className="question required">Conclusion Information</p>
            <div className="question__option">
              <QuillField
                name="about_delta"
                className="question__quill"
                delta={initialValues ? initialValues.about_delta : null}
              />
            </div>
          </div>
        </section> */}
        {isProcessing ? (
          <button className="btn-save btn-disabled btn-right" disabled>
            <img src={Loader} alt="loading" /> saving...
          </button>
        ) : (
          <button type="submit" className="btn-save btn-right">
            save
          </button>
        )}
      </div>
    );
  }

  renderEditForm() {
    const { edit, match, initialValues } = this.props;
    return (
      <div className=" admin">
        <div className="[challenge__edit]">
          <div className="bread-crumbs">
            <Link to="/builder-manager">Builder Manager </Link> &gt;{' '}
            <Link to={`/builder-manager/${match.params.ctb_id}`}>Track</Link>{' '}
            &gt;{' '}
            <Link
              to={`/builder-manager/${match.params.cbb_id}/${match.params.cb_id}`}
            >
              {' '}
              {initialValues ? `${initialValues.title}` : null} Builder
            </Link>{' '}
            &gt; Edit Builder
          </div>

          <h2 className="headtwo">
            Edit Builder{initialValues ? `- ${initialValues.title}` : null}
          </h2>
          <form onSubmit={(e) => edit(e, match.params.cb_id)}>
            {this.renderForm()}
          </form>
        </div>
      </div>
    );
  }

  renderAddForm() {
    const { create, match } = this.props;
    return (
      <div className=" admin">
        <div className="[challenge__edit]">
          <div className="bread-crumbs">
            <Link to="/builder-manager">Builder Manager </Link> &gt;{' '}
            <Link to={`/builder-manager/${match.params.ctb_id}`}>Track</Link>{' '}
            &gt; New Builder
          </div>
          <h2 className="headtwo">Add a New Builder</h2>
          <form onSubmit={(e) => create(e)}>{this.renderForm()}</form>
        </div>
      </div>
    );
  }

  render() {
    const { match, redirect, close } = this.props;

    // if save sucessful, redirect
    if (redirect) {
      close(); // close the modal
    }

    // check if editing a challenge blueprint
    if (match.params.cb_id) return this.renderEditForm();

    return this.renderAddForm();
  }
}

const mapDispatchToProps = (dispatch) => ({
  openCoverImageUploadForm() {
    dispatch({ type: OPEN_COVER_IMAGE_UPLOAD_MODAL });
  },
  create(event) {
    event.preventDefault();
    dispatch(addChallengeBlueprint());
  },
  close() {
    dispatch({ type: RESET_REDIRECT }); // prevent redirect
    window.history.back();
  },
  edit(event, cbId) {
    event.preventDefault();
    dispatch(updateChallengeBlueprint(cbId));
  },
});

const mapStateToProps = (state, props) => {
  const { metadata, challengeBuilder, form } = state;
  // if the currently editing an existing challenge bucket blueprint, get the existing values
  const challengeBlueprint = challengeBuilder.challengeBlueprints.find(
    (cb) => cb.get('id').toString() === props.match.params.cb_id
  );

  // set inital form values
  const initialValues = challengeBlueprint
    ? {
        title: challengeBlueprint.get('title'),
        author: challengeBlueprint.get('author'),
        level: challengeBlueprint.get('level'),
        approximate_time: challengeBlueprint.get('approximate_time'),
        cover_img: challengeBlueprint.get('cover_img'),
        intro_title: challengeBlueprint.get('intro_title'),
        about_title: challengeBlueprint.get('about_title'),
        about_delta: challengeBlueprint.get('about_delta'),
        track_sort_order: challengeBlueprint.get('track_sort_order'),
        bucket_sort_order: challengeBlueprint.get('bucket_sort_order'),
        title_spanish: challengeBlueprint.get('title_spanish'),
        intro_title_spanish: challengeBlueprint.get('intro_title_spanish'),
        about_delta_spanish: challengeBlueprint.get('about_delta_spanish'),
        about_title_spanish: challengeBlueprint.get('about_title_spanish'),
        learning_outcome1: challengeBlueprint.getIn(['learning_outcome', '1']),
        learning_outcome2: challengeBlueprint.getIn(['learning_outcome', '2']),
        learning_outcome3: challengeBlueprint.getIn(['learning_outcome', '3']),
        learning_outcome4: challengeBlueprint.getIn(['learning_outcome', '4']),
        learning_outcome5: challengeBlueprint.getIn(['learning_outcome', '5']),
        is_active: challengeBlueprint.get('is_active'),
        challenge_track_blueprint_id: challengeBlueprint.get(
          'challenge_track_blueprint_id'
        ),
        challenge_bucket_blueprint_id: challengeBlueprint.get(
          'challenge_bucket_blueprint_id'
        ),
      }
    : {
        challenge_track_blueprint_id: parseInt(props.match.params.ctb_id, 10),
      };

  const redirect = metadata.redirect;

  // get temporary cover image or existing cover image
  const tempImage =
    form.CoverImageUploadForm && form.CoverImageUploadForm.values
      ? form.CoverImageUploadForm.values.file[0]
      : null;
  const currentImage = challengeBlueprint
    ? challengeBlueprint.get('cover_img_url')
    : null;

  return {
    trackBlueprints: challengeBuilder.challengeTrackBlueprints.toJS(),
    bucketBlueprints: challengeBuilder.challengeBucketBlueprints.toJS(),
    redirect,
    initialValues,
    tempImage,
    currentImage,
    isProcessing: metadata.isProcessing,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'ChallengeBlueprintForm', enableReinitialize: true })(
    BuilderBlueprintForm
  )
);
