import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NewUsers from './newUsers'
import NewProjects from './newProjects';
import moment from 'moment';

class AdminHome extends Component {
  render() {
    const { newUsersCount, newProjectsCount, newHubsCount } = this.props;
    return (
      <div className="container-fluid">
        <div class="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
          {/* <a href="#" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a> */}
        </div>

        <div class="row">

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">New Users This Month</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{newUsersCount}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-user fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">New Projects This Month</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{newProjectsCount}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-table fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6 mb-4">
            <div class="card border-left-primary shadow h-100 py-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">New Hubs This Month</div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800">{newHubsCount}</div>
                  </div>
                  <div class="col-auto">
                    <i class="fas fa-table fa-2x text-gray-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Last 5 Sign Ups</h6>
              </div>
              <div className="card-body">
                <NewUsers />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Last 5 Projects Created</h6>
              </div>
              <div className="card-body">
                <NewProjects />
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

function mapStateToProps(state) {
  const newUsersCount = state.users.filter(user => moment(user.get('inserted_at')).isSame(new Date(), 'month')).count();

  const newProjectsCount = state.projects.filter(project => moment(project.get('inserted_at')).isSame(new Date(), 'month')).count();

  const newHubsCount = state.hubs.hubs.filter(hub => moment(hub.get('inserted_at')).isSame(new Date(), 'month')).count();
  return {
    newUsersCount: newUsersCount,
    newProjectsCount: newProjectsCount,
    newHubsCount: newHubsCount,
  };
}


export default connect(mapStateToProps)(AdminHome);
