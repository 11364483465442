import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import loader from 'images/loader.gif';
import { modalCss } from 'views/utils/modalCss';
import {
  addChallengeTrackBlueprint,
  updateChallengeTrackBlueprint,
} from 'store/builderManager/builderManagerActions';

import '../builderManager.scss';
import { RESET_REDIRECT } from 'store/metadata/metadataActions';

class TrackBlueprintForm extends React.Component {
  renderForm() {
    const { isProcessing } = this.props;
    return (
      <div>
        <div className="question__wrap">
          <p className="question required">Name</p>
          <div className="question__option">
            <Field
              type="text"
              component="input"
              name="name"
              placeholder="track name"
            />
          </div>
        </div>
        <div className="question__wrap">
          <p className="question required">Description</p>
          <div className="question__option">
            <Field
              type="text"
              component="input"
              name="description"
              placeholder="description"
            />
          </div>
        </div>
        <div className="question__wrap">
          <p className="question">Sort Order</p>
          <div className="question__option">
            <Field
              type="number"
              component="input"
              name="sort_order"
              placeholder=""
            />
          </div>
        </div>
        {isProcessing ? (
          <button className="btn-save btn-disabled btn-right" disabled>
            <img src={loader} alt="loading" /> saving...
          </button>
        ) : (
          <button type="submit" className="btn-save btn-right">
            save
          </button>
        )}
      </div>
    );
  }

  renderNewTrack() {
    const { close, create } = this.props;
    return (
      <Modal
        isOpen={true}
        style={modalCss}
        contentLabel="Add Challenge Track Blueprint Modal"
      >
        <div className="admin">
          <div className="[topic__edit]">
            <button onClick={() => close()} className="btn-x">
              x
            </button>
            <h2>New Track</h2>
            <form onSubmit={(e) => create(e)}>{this.renderForm()}</form>
          </div>
        </div>
      </Modal>
    );
  }

  renderEditTrack() {
    const { close, edit, match } = this.props;
    return (
      <Modal
        isOpen={true}
        style={modalCss}
        contentLabel="Edit Challenge Track Blueprint Modal"
      >
        <div className="admin">
          <div className="[topic__edit]">
            <button onClick={() => close()} className="btn-x">
              x
            </button>
            <h2>Edit Track</h2>
            <form onSubmit={(e) => edit(e, match.params.ctb_id)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { match, redirect, close } = this.props;

    // if save sucessful, redirect
    if (redirect) {
      close(); // close the modal
    }

    // check if editing
    if (match.params.ctb_id) {
      return this.renderEditTrack();
    }

    // empty form
    return this.renderNewTrack();
  }
}

const mapDispatchToProps = (dispatch) => ({
  close() {
    dispatch({ type: RESET_REDIRECT }); // prevent redirect
    window.history.back();
  },
  create(event) {
    event.preventDefault();
    dispatch(addChallengeTrackBlueprint());
  },
  edit(event, ctbId) {
    event.preventDefault();
    dispatch(updateChallengeTrackBlueprint(ctbId));
  },
});

const mapStateToProps = (state, props) => {
  // if the currently editing an existing challenge track blueprint, get the existing values
  const challengeTrackBlueprint = state.challengeBuilder.challengeTrackBlueprints.find(
    (ctb) => ctb.get('id').toString() === props.match.params.ctb_id
  );

  // set inital form values
  const initialValues = challengeTrackBlueprint
    ? {
        name: challengeTrackBlueprint.get('name'),
        sort_order: challengeTrackBlueprint.get('sort_order'),
        description: challengeTrackBlueprint.get('description'),
      }
    : null;

  const redirect = state.metadata.redirect;
  return {
    initialValues,
    redirect,
    isProcessing: state.metadata.isProcessing,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'ChallengeTrackBlueprintForm', enableReinitialize: true })(
    TrackBlueprintForm
  )
);
