import { combineReducers } from 'redux';
import { List, fromJS } from 'immutable';
import {
  SET_HUB_INVITATIONS,
  SET_HUB_INVITATION,
  DELETE_HUB_INVITATION,
  SET_HUBS,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTIONS,
  SET_HUB_LIMITS,
  UPDATE_HUB_INVITATION,
  RESET_HUB_INVITATION,
} from './hubActions';

function hubInvitations(state = List(), action) {
  switch (action.type) {
    case SET_HUB_INVITATIONS:
      return List(fromJS(action.data));
    case SET_HUB_INVITATION:
      return state.push(fromJS(action.data));
    case DELETE_HUB_INVITATION:
      return state.filterNot((i) => i.get('id') === action.id);
    default:
      return state;
  }
}

function hubs(state = List(), action) {
  switch (action.type) {
    case SET_HUBS:
      return List(fromJS(action.data));
    default:
      return state;
  }
}

function hub_limits(state = List(), action) {
  switch (action.type) {
    case SET_HUB_LIMITS:
      return List(fromJS(action.data));
    default:
      return state;
  }
}

function subscriptions(state = List(), action) {
  switch (action.type) {
    case SET_SUBSCRIPTION:
      return state.push(fromJS(action.data));
    case SET_SUBSCRIPTIONS:
      return List(fromJS(action.data));
    default:
      return state;
  }
}

function createHubInvitation(state = {}, action) {
  switch (action.type) {
    case UPDATE_HUB_INVITATION:
      return { ...state, ...action.data };
    case RESET_HUB_INVITATION:
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  hubInvitations,
  subscriptions,
  hubs,
  hub_limits,
  createHubInvitation,
});
