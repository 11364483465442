import React from 'react';
// import { Link } from 'react-router-dom';
import UserList from './userList';

const Accounts = () => (
  <div className="container-fluid">
    <h1 className="h3 mb-2 text-gray-800">Accounts</h1>
    <p className="mb-4">List of all user accounts.</p>
    <UserList />
  </div>
);

export default Accounts;
