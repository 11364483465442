import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateHubLimit } from 'store/hubs/hubActions';
import loader from 'images/loader.gif';
import HubLimitsForm from 'components/HubLimitsForm';
import * as lodash from 'lodash';

const AutoForm = ({
  id,
  max_number_of_active_groups,
  max_number_of_events_per_year,
  max_number_of_projects_per_event,
  max_number_of_hub_admins,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    setSubmitting(true);
    const onSuccess = () => {
      setSubmitting(false);
      setEditing(false);
    };
    if (
      values.max_number_of_active_groups === max_number_of_active_groups &&
      values.max_number_of_events_per_year === max_number_of_events_per_year &&
      values.max_number_of_projects_per_event ===
        max_number_of_projects_per_event &&
      values.max_number_of_hub_admins === max_number_of_hub_admins
    )
      onSuccess();
    else dispatch(updateHubLimit(id, values, onSuccess));
  };

  const renderDetail = (phrase, value) => (
    <p>{`${phrase}: ${value === -1 ? 'Unlimited' : value}`}</p>
  );

  return (
    <div>
      {isEditing ? (
        <HubLimitsForm
          initialValues={{
            max_number_of_active_groups,
            max_number_of_events_per_year,
            max_number_of_projects_per_event,
            max_number_of_hub_admins,
          }}
          primaryBtnLabel={isSubmitting ? <img src={loader} /> : 'Submit'}
          secondaryBtnLabel="Cancel"
          handleSubmit={handleSubmit}
          secondaryBtnAction={() => setEditing(false)}
          isPrimaryBtnDisabled={isSubmitting}
        />
      ) : (
        <div>
          {renderDetail(
            'Max number of active groups',
            max_number_of_active_groups
          )}
          {renderDetail(
            'Max number of events per year',
            max_number_of_events_per_year
          )}
          {renderDetail(
            'Max number of projects per event',
            max_number_of_projects_per_event
          )}
          {renderDetail('Max number of hub admins', max_number_of_hub_admins)}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setEditing(true)}
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

export default AutoForm;
