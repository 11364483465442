import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { publishChallengeBlueprint } from '../../../store/builderManager/builderManagerActions';
import loader from 'images/loader.gif';

const BuilderTile = ({ cb, ctb_id, topicCount, publish }) => {
  const [isProcessing, setProcessing] = useState(false);
  return (
    <li>
      <div className="track-view__drag-drop__left">
        <img className="track-view__cover-img" src={cb.cover_img_url} />
        <p className="track-view__builder-title">{cb.title}</p>
      </div>
      {!cb.is_published && (
        <button
          onClick={() => {
            setProcessing(true);
            publish(
              cb.id,
              () => setProcessing(false),
              () => setProcessing(false)
            );
          }}
          disabled={isProcessing}
        >
          {!isProcessing ? 'Publish' : <img src={loader} />}
        </button>
      )}
      <div className="track-view__drag-drop__left">
        <Link to={`/builder-manager/tracks/${ctb_id}/builders/${cb.id}`}>
          <p className="display__subtitle">
            {topicCount[cb.id] === 1
              ? '1 topic'
              : `${topicCount[cb.id]} topics`}{' '}
            <span className="display__subtitle--click">&gt;</span>
          </p>
        </Link>
      </div>
    </li>
  );
};

const TrackBlueprint = ({
  ctbTitle,
  match,
  challengeBlueprints,
  topicCount,
  publish,
}) => (
  <div className="container-fluid admin">
    <div className="[challenge]">
      <div className="bread-crumbs">
        <Link to="/builder-manager">Builder Manager</Link> &gt; {ctbTitle}
      </div>

      <div className="title__wrap">
        <div>
          <h3 className="headthree">Track</h3>
          <h2 className="headtwo">{ctbTitle}</h2>
        </div>
        <Link to={`/builder-manager/tracks/${match.params.ctb_id}/edit`}>
          <span className="edit-link">edit</span>
        </Link>
      </div>

      <h3 className="headthree">Builders</h3>
      <Link to={`/builder-manager/tracks/${match.params.ctb_id}/builders/add`}>
        <button className="btn-right">+ New Builder</button>
      </Link>
      {challengeBlueprints.length ? (
        <ul className="display__layout">
          {challengeBlueprints.map((cb) => (
            <BuilderTile
              key={`cb-${cb.id}`}
              cb={cb}
              ctb_id={match.params.ctb_id}
              topicCount={topicCount}
              publish={publish}
            />
          ))}
        </ul>
      ) : (
        <div>No builders</div>
      )}
    </div>
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  publish(cbId, onSuccessCb, onErrorCb) {
    dispatch(publishChallengeBlueprint(cbId, onSuccessCb, onErrorCb));
  },
});

const mapStateToProps = (state, props) => {
  const { topicBlueprints, challengeTrackBlueprints } = state.challengeBuilder;

  // get challenge track blueprint info
  const challengeTrackBlueprint = challengeTrackBlueprints.find(
    (ctb) => ctb.get('id').toString() === props.match.params.ctb_id
  );
  const ctbTitle = challengeTrackBlueprint
    ? challengeTrackBlueprint.get('name')
    : null;

  // get all challenge blueprints that belong to this challenge track blueprint
  const challengeBlueprints = state.challengeBuilder.challengeBlueprints.filter(
    (cb) =>
      cb.get('challenge_track_blueprint_id') ===
      parseInt(props.match.params.ctb_id, 10)
  );

  // get topic count
  const topicCount = {};
  challengeBlueprints.forEach((cb) => {
    const cbId = cb.get('id');
    const count = topicBlueprints
      .filter((tb) => tb.get('challenge_blueprint_id') === cbId)
      .count();
    topicCount[cbId] = count;
  });
  return {
    ctbTitle,
    challengeBlueprints: challengeBlueprints
      .sortBy((cb) => cb.get('track_sort_order'))
      .toJS(),
    topicCount,
    isProcessing: state.metadata.isProcessing,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackBlueprint);
