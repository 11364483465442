import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCustomers } from 'store/customers/customerActions';

class Customers extends Component {
  componentWillUnmount() {
    this.props.fetchAllCustomers();
  }

  render() {
    const customers = this.props.customers;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h3 className="m-0 font-weight-bold text-primary">Customers</h3>
              </div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th />
                      <th>Organization Name</th>
                      <th>Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            <Link to={`/customers/${customer.id}`}>
                              {customer.organization_name}
                            </Link>
                          </td>
                          <td>{customer.id}</td>
                          {/* <td>
                            <Link to={`/customers/edit/${customer.id}`}>
                              edit
                            </Link>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchAllCustomers() {
    dispatch(fetchCustomers());
  },
});

function mapStateToProps(state) {
  const { customers } = state;
  return {
    customers: customers.customers.toJS(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
