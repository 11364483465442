import { Map, fromJS } from 'immutable';
import { SHOW_SIDE_NAV, HIDE_SIDE_NAV } from './sideNavActions';

// list of notifications
const initSideNav = Map(fromJS({
  menuOpen: true,
}));
function sideNav(state = initSideNav, action) {
  switch (action.type) {
    case SHOW_SIDE_NAV:
      return state.set('menuOpen', true);
    case HIDE_SIDE_NAV:
      return state.set('menuOpen', false);
    default:
      return state;
  }
}

export default sideNav;
