import { List, fromJS } from 'immutable';
import { SET_PROJECTS } from './projectActions';


const initAccounts = List();
function projects(state = initAccounts, action) {
  switch (action.type) {
    case SET_PROJECTS:
      return state.concat(fromJS(action.data));
    default:
      return state;
  }
}


export default projects;
