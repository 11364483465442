import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  createHubInvitation,
  deleteHubInvitation,
} from '../../../store/hubs/hubActions';
import '../../../css/tables.scss';
import HubInvitation from 'components/HubInvitation';

class HubInvitations extends Component {
  renderPendingInvitationList() {
    const { pendingHubInvitations, deleteInvitation } = this.props;
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h4 className="m-0 font-weight-bold text-primary">
                Pending Hub Invitations
              </h4>
            </div>
            <div className="card-body">
              {pendingHubInvitations.length <= 0 ? (
                <span>No Pending Invitations</span>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th />
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Hub Name</th>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {pendingHubInvitations.map((i, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{i.name}</td>
                          <td>{i.email_address}</td>
                          <td>{i.hub_name}</td>
                          <td />
                          <td>
                            <a
                              role="button"
                              tabIndex="0"
                              onClick={() => deleteInvitation(i.id)}
                            >
                              delete
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAcceptedInvitationList() {
    const { acceptedHubInvitations } = this.props;
    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-header py-3">
              <h4 className="m-0 font-weight-bold text-primary">
                Accepted Hub Invitations
              </h4>
            </div>
            <div className="card-body">
              {acceptedHubInvitations.length <= 0 ? (
                <span>No Accepted Invitations</span>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th />
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Hub Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {acceptedHubInvitations.map((i, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{i.name}</td>
                          <td>{i.email_address}</td>
                          <td>{i.hub_name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <Link to={'/hubs'}>
          <span>&#60; go to hubs</span>
        </Link>
        <hr />

        <HubInvitation />
        {this.renderPendingInvitationList()}
        {this.renderAcceptedInvitationList()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendInvitation(e) {
    e.preventDefault();
    dispatch(createHubInvitation());
  },
  deleteInvitation(id) {
    dispatch(deleteHubInvitation(id));
  },
});

const mapStateToProps = (state) => {
  const { hubs } = state;

  return {
    pendingHubInvitations: hubs.hubInvitations
      .filter((i) => !i?.get('is_accepted'))
      .toJS(),
    acceptedHubInvitations: hubs.hubInvitations
      .filter((i) => i?.get('is_accepted'))
      .toJS(),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HubInvitations);
