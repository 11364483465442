import React from 'react';
import { connect } from 'react-redux';

import './loading.scss';

class Loading extends React.Component {
  static renderLoading() {
    return (
      <div className="loading__wrapper">
        <div className="loading">
          <h2>
            <em>L</em>
            <em className="planet left">O</em>
            <em>A</em>
            <em>D</em>
            <em>I</em><em>N</em><em>G</em>
          </h2>
        </div>
      </div>
    );
  }

  render() {
    const { isLoaded } = this.props;

    if (!isLoaded) {
      return this.constructor.renderLoading();
    }
    return null;
  }
}

function mapStateToProps(state) {
  const { metadata } = state;

  // loading condition
  const isLoaded = metadata.isLoaded;

  return {
    isLoaded,
  };
}

export default connect(mapStateToProps)(Loading);
