import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Cookie from 'js-cookie';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const jwt = Cookie.get('admin_token');

  return jwt ? (
    <Route component={Component} {...rest} />
  ) : (
    <Redirect to={'/login'} />
  );
};

export default PrivateRoute;
