import { request } from 'utils/functions';

// ACTION TYPES
export const SET_USER = 'SET_USER';
export const SET_JWT = 'SET_JWT';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_EMAIL = 'SET_EMAIL';
export const REMOVE_JWT = 'REMOVE_JWT';

// ACTION CREATORS
export function setUser(data) {
  return {
    type: SET_USER,
    data,
  };
}

export function fetchUser() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v1/rebel/settings/users';
    return request(url, jwt).then((response) =>
      dispatch(setUser(response.data[0]))
    );
  };
}

export function setStoreLogin(jwt) {
  return (dispatch) => {
    dispatch({
      type: SET_JWT,
      jwt,
    });
  };
}

export function removejwt() {
  return (dispatch) => {
    dispatch({
      type: REMOVE_JWT,
    });
  };
}
