import React from 'react';
import { connect } from 'react-redux';
import { FieldArray, Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import loader from 'images/loader.gif';
import '../builderManager.scss';
import { RESET_REDIRECT } from 'store/metadata/metadataActions';
import {
  addAnswerBlueprint,
  updateAnswerBlueprint,
} from 'store/builderManager/builderManagerActions';

const renderOptions = ({ fields, meta: { error, submitFailed } }) => (
  <ul>
    {fields.map((option, index) => (
      <li key={`option-${index + 1}`}>
        <div className="question__wrap">
          <p className="question">Option {index + 1}</p>
          <div className="question__option__enclosed">
            <Field type="text" component="input" name={`${option}.value`} />
            <button type="button" onClick={() => fields.remove(index)}>
              X
            </button>
          </div>
        </div>
      </li>
    ))}
    <li>
      <button type="button" onClick={() => fields.push({})}>
        + add option
      </button>
      {submitFailed && error && <span>{error}</span>}
    </li>
  </ul>
);

class AnswerBlueprintForm extends React.Component {
  renderForm() {
    const { formRequiresOptions, isProcessing } = this.props;
    return (
      <div>
        <div className="question__wrap">
          <p className="question">Answer Title</p>
          <div className="question__option">
            <Field
              type="text"
              component="input"
              name="title"
              placeholder="i.e. Problem Statement or Impact Metrics"
            />
          </div>
        </div>
        <div className="question__wrap">
          <p className="question">Sort Order</p>
          <div className="question__option">
            <Field type="number" component="input" name="sort_order" />
          </div>
        </div>
        <div className="question__wrap">
          <p className="question required">Type</p>
          <div className="question__option">
            <Field name="type" component="select">
              <option />
              {/* <option value="Bio Data Array">Bio Data Array</option>
              <option value="Input Box">Input Box</option> */}
              <option value="Quill Editor">Quill Editor</option>
              {/* <option value="Radial Choices">Radial Choices</option>
              <option value="Textarea">Textarea</option> */}
              {/* <option value="Checkboxes">Checkboxes</option> */}
              {/* <option value="Google Drive Upload">Google Drive Upload</option> */}
            </Field>
            {formRequiresOptions && (
              <div className="[Checkboxes or Radial Options]">
                <FieldArray name="options" component={renderOptions} />
              </div>
            )}
            <div className="question__wrap">
              <p className="question">Type Label</p>
              <div className="question__option__enclosed">
                <Field
                  name="type_label"
                  component="input"
                  placeholder="i.e. profile picture or inputs"
                  type="text"
                />
              </div>
            </div>
          </div>
          {/* <button className="btn-right">+ add answer type</button> */}
        </div>
        {isProcessing ? (
          <button className="btn-save btn-disabled btn-right" disabled>
            <img src={loader} alt="loading" /> saving...
          </button>
        ) : (
          <button type="submit" className="btn-save btn-right">
            save
          </button>
        )}
      </div>
    );
  }

  renderEditAnswer() {
    const { match, edit } = this.props;
    const { ctb_id, cb_id, tb_id, ab_id } = match.params;
    return (
      <div className="admin">
        <div className="[answer_edit]">
          <div className="bread-crumbs">
            <Link to="/builder-manager">Builder Manager </Link> >{' '}
            <Link to={`/builder-manager/tracks/${match.params.ctb_id}`}>
              Track
            </Link>{' '}
            >{' '}
            <Link
              to={`/builder-manager/tracks/${match.params.ctb_id}/bulders/${match.params.cb_id}`}
            >
              Builder
            </Link>{' '}
            >{' '}
            <Link
              to={`/builder-manager/tracks/${ctb_id}/builders/${cb_id}/topics/${tb_id}`}
            >
              Topic
            </Link>{' '}
            > Edit Answer
          </div>
          <h2>Edit Answer</h2>
          <form onSubmit={(e) => edit(e, ab_id)}>{this.renderForm()}</form>
        </div>
      </div>
    );
  }

  renderNewAnswer() {
    const { match, create } = this.props;
    const { ctb_id, cb_id, tb_id } = match.params;
    return (
      <div className="admin">
        <div className="[answer_edit]">
          <div className="bread-crumbs">
            <Link to="/builder-manager">Builder Manager </Link> >{' '}
            <Link to={`/builder-manager/tracks/${match.params.ctb_id}`}>
              Track
            </Link>{' '}
            >{' '}
            <Link
              to={`/builder-manager/tracks/${match.params.ctb_id}/bulders/${match.params.cb_id}`}
            >
              Builder
            </Link>{' '}
            >{' '}
            <Link
              to={`/builder-manager/tracks/${ctb_id}/builders/${cb_id}/topics/${tb_id}`}
            >
              Topic
            </Link>{' '}
            > New Answer
          </div>
          <h2>New Answer</h2>
          <form
            onSubmit={(e) =>
              create(
                e,
                tb_id,
                `/builder-manager/tracks/${ctb_id}/builders/${cb_id}/topics/${tb_id}`
              )
            }
          >
            {this.renderForm()}
          </form>
        </div>
      </div>
    );
  }

  render() {
    const { match, redirect, close } = this.props;

    // if save sucessful, redirect
    if (redirect) {
      close(); // close the modal
    }

    // check if editing existing answer blueprint
    if (match.params.ab_id) {
      return this.renderEditAnswer();
    }
    return this.renderNewAnswer();
  }
}

const mapDispatchToProps = (dispatch) => ({
  close() {
    dispatch({ type: RESET_REDIRECT }); // prevent redirect
    window.history.back();
  },
  create(event, tbId, redirectTo) {
    event.preventDefault();
    dispatch(addAnswerBlueprint(tbId, redirectTo));
  },
  edit(event, abId) {
    event.preventDefault();
    dispatch(updateAnswerBlueprint(abId));
  },
});

const mapStateToProps = (state, props) => {
  const { challengeBuilder, form, metadata } = state;
  const answerForm = form.AnswerBlueprintForm;

  // check if editing
  const answerBlueprint = challengeBuilder.answerBlueprints.find(
    (ab) => ab.get('id').toString() === props.match.params.ab_id
  );

  const initialValues = answerBlueprint
    ? {
        title: answerBlueprint.get('title'),
        sort_order: answerBlueprint.get('sort_order'),
        type: answerBlueprint.get('type'),
        type_label: answerBlueprint.get('type_label'),
      }
    : null;

  const options = answerBlueprint ? answerBlueprint.get('options') : null;
  if (options) {
    const optionsArray = [];
    options.map((o) => optionsArray.push(o.toJS()));
    initialValues.options = optionsArray;
  }

  // answer types which require additonal options
  const formRequiresOptions =
    answerForm &&
    answerForm.values &&
    (answerForm.values.type === 'Checkboxes' ||
      answerForm.values.type === 'Radial Choices');

  const { redirect } = metadata;
  return {
    formRequiresOptions,
    initialValues,
    redirect,
    isProcessing: metadata.isProcessing,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'AnswerBlueprintForm', enableReinitialize: true })(
    AnswerBlueprintForm
  )
);
