import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/tables.scss';
import { formatDate } from '../../utils/functions';

class NewProjects extends Component {
  render() {
    return (
      <div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>Id</th>
                <th>Name </th>
                <th>Inserted At</th>
                <th>Is Active</th>
                <th>Is Public</th>
              </tr>
            </thead>
            <tbody>
              {this.props.projectsLoaded &&
                this.props.projects.map((project, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{project.id}</td>
                      <td>{project.name}</td>
                      <td>{formatDate(project.inserted_at)}</td>
                      <td>{project.is_active ? 'yes' : 'no'}</td>
                      <td>{project.is_public ? 'yes' : 'no'}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projects: state.projects
      .sortBy(project => project.get('inserted_at'))
      .reverse()
      .setSize(5)
      .toJS(),
    projectsLoaded: !state.projects.isEmpty()
  };
}

export default connect(mapStateToProps)(NewProjects);
