import { request } from 'utils/functions';

export const SET_USERS = 'SET_USERS';

export function setAccounts(data) {
  return {
    type: SET_USERS,
    data,
  };
}

export function fetchUsers() {
  return (dispatch, getState) => {
    const { user } = getState();
    const jwt = user.get('jwt');
    const url =
      window.env.REACT_APP_API_ENDPOINT + '/api/v2/admin/accounts/users';
    return request(url, jwt).then((response) =>
      dispatch(setAccounts(response.data))
    );
  };
}
