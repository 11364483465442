import React from 'react';
import { match } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Customer } from '../../../utils/types';
import { formatDate } from '../../../utils/functions';
import { List, Map } from 'immutable';

interface Props {
  match: match<{ c_id: string }>;
}

const SingleCustomer: React.FC<Props> = ({ match }) => {
  const customers: List<Map<string, string | number>> = useSelector((state) => {
    const appState = state as any;
    return appState.customers?.customers;
  });

  if (!customers || customers.size === 0) return <p>Loading...</p>;

  const customer: Customer | undefined = customers.filter(
    (cust) => cust.get('id') === parseInt(match.params.c_id, 10)
  ).toJS()[0] as Customer | undefined;

  if (!customer) return <h1>Customer not found</h1>;

  const customerRow = (
    label: string,
    value: string | number | null
  ): React.ReactChild => (
    <div>
      <p>
        <span className="font-weight-bold">{label}: </span>
        {value ? value : ''}
      </p>
    </div>
  );

  const subHeading = (subHeading: string): React.ReactChild => (
    <h3 className="mb-3">{subHeading}</h3>
  );

  return (
    <div className="container-fluid">
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h1>{customer.organization_name}</h1>
        </div>
        <div className="card-body">
          {customerRow('Customer ID', customer.id)}
          {customerRow('Topic', customer.organization_topic)}
          {customerRow('Address', customer.organization_address)}
          {customerRow('Industry Type', customer.organization_industry_type)}
          {customerRow('Tax Number', customer.tax_number)}
          {customerRow('Number of Users', customer.number_of_users)}
          {customerRow(
            'Potential Number of Users',
            customer.potential_number_of_users
          )}
          {customerRow('End User Level', customer.end_user_level)}
          {customerRow('Growth Potential', customer.growth_potential)}
          {customerRow(
            'Is Social Impact important',
            customer.is_social_impact_important ? 'Yes' : 'No'
          )}
          {customerRow('Created At', formatDate(customer.inserted_at))}
          <hr />
          {subHeading('Primary Contact Details')}
          {customerRow(
            'E-mail address',
            customer.primary_contact_email_address
          )}
          {customerRow('First Name', customer.primary_contact_first_name)}
          {customerRow('Last Name', customer.primary_contact_last_name)}
          {customerRow('Phone Number', customer.primary_phone_number)}
          <hr />
          {subHeading('Secondary Contact Details')}
          {customerRow(
            'E-mail address',
            customer.secondary_contact_email_address
          )}
          {customerRow('First Name', customer.secondary_contact_first_name)}
          {customerRow('Last Name', customer.secondary_contact_last_name)}
          {customerRow('Phone Number', customer.secondary_phone_number)}
        </div>
      </div>
    </div>
  );
};

export default SingleCustomer;
